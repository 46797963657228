import React, { useState, useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import "./Tiers.css";

import silverTier from "../../../assets/img/silver-tier.svg";
import goldTier from "../../../assets/img/gold-tier.svg";
import sapphireTier from "../../../assets/img/sapphire-tier.svg";
import emeraldTier from "../../../assets/img/emerald-tier.svg";
import rubyTier from "../../../assets/img/ruby-tier.svg";
import diamondTier from "../../../assets/img/diamond-tier.svg";

import { useTranslation } from "react-i18next";
const Tiers = () => {
  const { t } = useTranslation();
  // const ref = useRef();
  // const { events } = useDraggable(ref, { safeDisplacement: 11 });
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  const scrollContainerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDown(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
    setScrolling(true);
    // Change cursor to grabbing
    scrollContainerRef.current.style.cursor = "grabbing";
  };

  const handleMouseLeave = () => {
    if (isDown) {
      setIsDown(false);
      setScrolling(false);
      // Reset cursor
      scrollContainerRef.current.style.cursor = "grab";
    }
  };

  const handleMouseUp = () => {
    if (isDown) {
      setIsDown(false);
      setScrolling(false);
      // Reset cursor
      scrollContainerRef.current.style.cursor = "grab";
    }
  };

  const handleMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust this value for faster or slower scrolling
    const newScrollLeft = scrollLeft - walk;
    scrollContainerRef.current.scrollLeft = newScrollLeft;
  };

  const handleTransitionEnd = () => {
    setScrolling(false);
  };
  return (
    <div className="section-padding">
      <div className="SectionHeaderV2_SectionHeaderV2__UELE4 home_sectionHeader__suL52 max-width">
        <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ mb34 text-center launchpad-tiers-heading">
          {t("homePage.tiers.title")}
        </div>
      </div>
      <div className="section svelte-16vqnfu max-width">
        <div className="header svelte-bi7erv">
          <div className="sub-header svelte-bi7erv">
            <p
              style={{}}
              className="weight-normal line-height-default align-center size-base text-size-base variant-subtle with-icon-space svelte-17v69ua"
            />
          </div>
        </div>

        <div className="wrapper svelte-1j0scf9">
          <div
            className={`slider-wrapper scrollX hide-scrollbar svelte-1j0scf9  `}
            style={{ gridAutoColumns: "calc(26%)" }}
            ref={scrollContainerRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTransitionEnd={handleTransitionEnd}
          >
            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={silverTier} alt="Silver Tier" />
                </div>
                <div className="card-header-line svelte-baey6h" />
              </div>
              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#bfbfbf" }}
                >
                  <span
                    style={{}}
                    className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.silverTier.silver")}
                  </span>
                </div>
                <div className="card-amount svelte-baey6h">
                  <span
                    style={{}}
                    className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color"
                  >
                    {t("homePage.tiers.silverTier.amount")}
                  </span>
                  <span
                    style={{}}
                    className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.silverTier.lottery")}
                  </span>
                </div>
                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "rgb(191, 191, 191)" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.silverTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "rgb(191, 191, 191)" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.silverTier.feature2")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "rgb(191, 191, 191)" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.silverTier.feature3")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={goldTier} />
                </div>

                <div className="card-header-line svelte-baey6h" />
              </div>

              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#ffc35c" }}
                >
                  <span
                    style={{}}
                    className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.goldTier.gold")}
                  </span>
                </div>

                <div className="card-amount svelte-baey6h">
                  <span
                    style={{}}
                    className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color"
                  >
                    {t("homePage.tiers.goldTier.amount")}
                  </span>

                  <span
                    style={{}}
                    className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.goldTier.lottery")}
                  </span>
                </div>

                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#ffb640" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.goldTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#ffb640" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.goldTier.feature2")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#ffb640" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.goldTier.feature3")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={sapphireTier} />
                </div>

                <div className="card-header-line svelte-baey6h" />
              </div>

              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#674ab5" }}
                >
                  <span
                    style={{}}
                    className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.sapphireTier.sapphire")}
                  </span>
                </div>

                <div className="card-amount svelte-baey6h">
                  <span
                    style={{}}
                    className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color"
                  >
                    {t("homePage.tiers.sapphireTier.amount")}
                  </span>

                  <span
                    style={{}}
                    className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.sapphireTier.lottery")}
                  </span>
                </div>

                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#674ab5" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.sapphireTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#674ab5" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.sapphireTier.feature2")}
                    </span>
                  </li>

                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#674ab5" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.sapphireTier.feature3")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#674ab5" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.sapphireTier.feature4")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={emeraldTier} />
                </div>

                <div className="card-header-line svelte-baey6h" />
              </div>

              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#9ee65c" }}
                >
                  <span
                    style={{}}
                    className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.emeraldTier.emerald")}
                  </span>
                </div>

                <div className="card-amount svelte-baey6h">
                  <span
                    style={{}}
                    className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color"
                  >
                    {t("homePage.tiers.emeraldTier.amount")}
                  </span>

                  <span
                    style={{}}
                    className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.emeraldTier.guaranteedAllocation")}
                  </span>
                </div>

                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#59b347" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.emeraldTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#59b347" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.emeraldTier.feature2")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#59b347" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.emeraldTier.feature3")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#59b347" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />

                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.emeraldTier.feature4")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={rubyTier} alt="Ruby Tier" />
                </div>

                <div className="card-header-line svelte-baey6h" />
              </div>

              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#c52b54" }}
                >
                  <span
                    style={{}}
                    className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.rubyTier.ruby")}
                  </span>
                </div>

                <div className="card-amount svelte-baey6h">
                  <span
                    style={{}}
                    className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color"
                  >
                    {t("homePage.tiers.rubyTier.amount")}
                  </span>

                  <span
                    style={{}}
                    className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua"
                  >
                    {t("homePage.tiers.rubyTier.guaranteedAllocation")}
                  </span>
                </div>

                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature2")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature3")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature4")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature5")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature6")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#c52b54" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>

                    <span
                      style={{}}
                      className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu"
                    >
                      {t("homePage.tiers.rubyTier.feature7")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-wrapper svelte-baey6h">
              <div className="card-header svelte-baey6h">
                <div className="card-header-icon svelte-baey6h">
                  <img src={diamondTier} alt="Diamond Tier" />
                </div>
                <div className="card-header-line svelte-baey6h" />
              </div>

              <div className="card svelte-baey6h" style={{}}>
                <div
                  className="card-label svelte-baey6h"
                  style={{ backgroundColor: "#3ca1d7" }}
                >
                  <span className="weight-normal line-height-default align-left size-default text-size-default variant-contrast with-icon-space svelte-17v69ua">
                    {t("homePage.tiers.diamondTier.diamond")}
                  </span>
                </div>

                <div className="card-amount svelte-baey6h">
                  <span className="weight-semibold line-height-none align-left size-2xl text-size-2xl variant-highlighted with-icon-space svelte-17v69ua tier-heading-color">
                    {t("homePage.tiers.diamondTier.amount")}
                  </span>
                  <span className="weight-normal line-height-none align-left size-default text-size-default variant-subtle with-icon-space svelte-17v69ua">
                    {t("homePage.tiers.diamondTier.guaranteedAllocation")}
                  </span>
                </div>

                <ul className="card-bonus-list svelte-baey6h">
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature1")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature2")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature3")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature4")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature5")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature6")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature7")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature8")}
                    </span>
                  </li>
                  <li className="bonus svelte-baey6h">
                    <div
                      className="bonus-icon svelte-baey6h"
                      style={{ backgroundColor: "#3ca1d7" }}
                    >
                      <svg
                        fill="currentColor"
                        viewBox="0 0 64 64"
                        className="svg-icon"
                        style={{ color: "var(--grey-700)" }}
                      >
                        <title />
                        <path d="M26.922 50.04 8.147 32.014l7.175-7.174 11.6 10.906L48.708 13.96l7.145 7.146L26.922 50.04Z" />
                      </svg>
                    </div>
                    <span className="weight-normal line-height-default align-left size-default text-size-default variant-highlighted with-icon-space svelte-17v69ua ftwu">
                      {t("homePage.tiers.diamondTier.feature9")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiers;
