import { useDispatch } from "react-redux";
import { setTotalSnovaTokens } from "../reducers/totalSnovaTokensSlice";
import { setTotalSnovaValue } from "../reducers/totalSnovaValueSlice";
import { setAllTransactions } from "../reducers/transactionsSlice";
import { setTotalNovaPoints } from "../reducers/totalNovaPointsSlice";
import {
  setTotalReferralRewards,
  setPendingReferralRewardsInDollars,
} from "../reducers/totalReferralRewardsSlice";
import { setUserRankingByRewards } from "../reducers/userRankingByRewardsSlice";
import { setUserRankingByNovaPoints } from "../reducers/userRankingByNovaPointsSlice";
import { setNovaPoints } from "../reducers/amountsSlice";
import { setRewards } from "../reducers/referralRewardsSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const useFetchInvestorDashboard = () => {
  const dispatch = useDispatch();
  const { address } = useWeb3ModalAccount();

  const fetchInvestorDashboard = async () => {
    const cachedData = localStorage.getItem("investorDashboardData");
    const lastFetchTime = localStorage.getItem("investorDashboardFetchTime");
    const now = Date.now();

    if (cachedData && lastFetchTime && now - lastFetchTime < 60000) {
      // 1 minute cache
      // console.log("Using cached data");
      const data = JSON.parse(cachedData);
      dispatchData(data);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getInvestorDashboard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("investorDashboardData", JSON.stringify(data));
        localStorage.setItem("investorDashboardFetchTime", Date.now());
        dispatchData(data);
        await fetchAndDispatchUserRankings(); // Fetch and dispatch user rankings after dispatching other data
      } else {
        const errorData = await response.json();
        console.error(
          "Error fetching investor dashboard data:",
          errorData.error
        );
      }
    } catch (error) {
      console.error("Error fetching investor dashboard data:", error.message);
    }
  };

  const fetchAndDispatchUserRankings = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getUserRankings?wallet_address=${address}`
      );

      if (response.ok) {
        const data = await response.json();
        dispatch(setUserRankingByRewards(data.rewards_ranking));
        dispatch(setUserRankingByNovaPoints(data.nova_points_ranking));
      } else {
        const errorData = await response.json();
        console.error("Error fetching user rankings:", errorData.error);
      }
    } catch (error) {
      console.error("Error fetching user rankings:", error.message);
    }
  };

  const dispatchData = (data) => {
    dispatch(setTotalSnovaTokens(data.totalSnovaTokens));
    dispatch(setRewards({ SNOVA: data.earnedSnovaTokens }));
    dispatch(setTotalSnovaValue(data.totalSnovaValue));
    dispatch(setAllTransactions(data.transactions));
    dispatch(setTotalNovaPoints(data.totalNovaPoints));
    dispatch(setNovaPoints(data.earnedNovaPoints));
    dispatch(setTotalReferralRewards(data.totalReferralRewardsInDollars));
    dispatch(
      setPendingReferralRewardsInDollars(data.pendingReferralRewardsInDollars)
    );
  };

  return { fetchInvestorDashboard };
};

export default useFetchInvestorDashboard;
