import React from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import done from "../../../../assets/img/done.svg";
import copy from "../../../../assets/img/copy.svg";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UserIcon from "../../../../assets/img/user-profile.svg";
import CircleInfo from "../../../../assets/img/circle-info.svg";
import ShimmerPlaceholder from "./shimmer/ShimmerPlaceholderBalance";

const SNOVAbalanceContent = ({ copied, handleCopy }) => {
  const { isConnected } = useWeb3ModalAccount();
  const totalSnovaTokens = useSelector(
    (state) => state.totalSnovaTokens.totalSnovaTokens
  );
  const totalSnovaValue = useSelector(
    (state) => state.totalSnovaValue.totalSnovaValue
  );
  const totalReferralRewardsInDollars = useSelector(
    (state) => state.totalReferralRewards.totalReferralRewardsInDollars
  );
  const referralLink = useSelector((state) => state.referral.referral);

  const { t } = useTranslation();

  // Check if any of the data is undefined to show loading shimmer
  const loading =
    totalSnovaTokens === null ||
    totalSnovaValue === null ||
    totalReferralRewardsInDollars === null ||
    referralLink === null;

  if (!isConnected) {
    return null;
  }

  if (loading) {
    return <ShimmerPlaceholder />;
  }

  return (
    <div className="rt-Box sc-eDLKkx jmXpMk" style={{ marginBottom: 0 }}>
      <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-5">
        <div className="rt-Grid rt-r-gap-4 gats">
          <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-2">
            <h1
              data-accent-color="expressive"
              fontWeight={700}
              className="rt-Heading sc-fsYfdN eCOegk rt-r-size-5 rt-r-weight-bold"
            >
              {t("homePage.banner.yourSNOVATokens")}
              <Link
                to="/dashboard"
                data-tooltip-place="bottom"
                data-tooltip-class-name="mytool"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("toolTips.InvesterTip")}
                style={{ color: "inherit" }}
              >
                <span
                  role="img"
                  aria-label="user-circle"
                  className="anticon anticon-info-circle"
                  style={{ marginLeft: 5 }}
                >
                  <img src={UserIcon} style={{ height: "20px" }} />
                </span>
              </Link>
            </h1>
            <div className="rt-Flex rt-r-display-flex rt-r-jc-start rt-r-gap-1">
              <h1 className="rt-Heading sc-fsYfdN giQdiX rt-r-size-7 rt-r-weight-bold">
                {totalSnovaTokens ? totalSnovaTokens.toFixed(2) : 0}
              </h1>
              <div
                className="rt-Flex rt-r-display-flex rt-r-ai-center rt-r-jc-start rt-r-pb-1"
                style={{ alignSelf: "flex-end" }}
              >
                <span
                  className="rt-Text sc-qZrbh iHUxZB"
                  style={{ fontSize: "16px" }}
                >
                  {totalSnovaValue
                    ? "/ $" + totalSnovaValue.toFixed(2)
                    : "/ $0"}
                </span>
              </div>
            </div>
          </div>
          <div className="rt-Flex rt-r-display-flex rt-r-fd-column rt-r-jc-start rt-r-gap-2">
            <h1
              data-accent-color="expressive"
              fontWeight={700}
              className="rt-Heading sc-fsYfdN eCOegk rt-r-size-5 rt-r-weight-bold"
            >
              {t("homePage.banner.referralEarnings")}
              <Link
                to="/referral"
                data-tooltip-place="bottom"
                data-tooltip-class-name="mytool"
                data-tooltip-id="my-tooltip"
                data-tooltip-content={t("toolTips.learnMore")}
                style={{ color: "inherit" }}
              >
                <span
                  role="img"
                  aria-label="info-circle"
                  className="anticon anticon-info-circle"
                  style={{ marginLeft: 5 }}
                >
                  <img src={CircleInfo} style={{ height: "20px" }} />
                </span>
              </Link>
            </h1>
            <div className="rt-Flex rt-r-display-flex rt-r-jc-start rt-r-gap-1">
              <h1 className="rt-Heading sc-fsYfdN giQdiX rt-r-size-7 rt-r-weight-bold">
                {totalReferralRewardsInDollars
                  ? "$" + totalReferralRewardsInDollars.toFixed(2)
                  : "$0"}
              </h1>
              <div
                className="rt-Flex rt-r-display-flex rt-r-ai-center rt-r-jc-start rt-r-pb-1"
                style={{ alignSelf: "flex-end" }}
              >
                <span
                  className="rt-Text sc-qZrbh flex items-center iHUxZB"
                  style={{ fontSize: "12px" }}
                >
                  / {t("homePage.banner.myReferralLink")}{" "}
                  {copied ? (
                    <div className="_copyBtn_1fjrj_20 maer ">
                      <img src={done} className="don-ing" alt="Done" />
                    </div>
                  ) : (
                    <div
                      className="_copyBtn_1fjrj_20 maer"
                      onClick={() => handleCopy(referralLink)}
                    >
                      <img src={copy} alt="Copy" />
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default SNOVAbalanceContent;
