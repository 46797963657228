import { createSlice } from "@reduxjs/toolkit";
import blockchainPriceFeeds from "../utils/priceFeedsByChainId";

const initialState = {
  selectedCurrency: localStorage.getItem("selectedCurrency") || "ETH",
  selectedFiat: localStorage.getItem("selectedFiat") || "USD",
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setSelectedCurrency(state, action) {
      state.selectedCurrency = action.payload;
      localStorage.setItem("selectedCurrency", action.payload);
    },
    setSelectedFiat(state, action) {
      state.selectedFiat = action.payload;
      localStorage.setItem("selectedFiat", action.payload);
    },
    initializeCurrency(state, action) {
      const storedCurrency = localStorage.getItem("selectedCurrency");
      if (storedCurrency) {
        state.selectedCurrency = storedCurrency;
      } else if (
        action.payload.chainId &&
        blockchainPriceFeeds[action.payload.chainId]
      ) {
        const currencies = Object.keys(
          blockchainPriceFeeds[action.payload.chainId]
        );
        if (currencies.length > 0) {
          state.selectedCurrency = currencies[0];
        }
      }
    },
  },
});

export const { setSelectedCurrency, setSelectedFiat, initializeCurrency } =
  currencySlice.actions;
export default currencySlice.reducer;
