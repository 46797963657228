import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rewards: null,
  totalRewards: "0",
  allRewards: undefined, // Initialize as an empty object
  totalValuesByChain: {},
};

const referralRewardsSlice = createSlice({
  name: "referralRewards",
  initialState,
  reducers: {
    setRewards(state, action) {
      state.rewards = action.payload;
    },
    setTotalRewards(state, action) {
      state.totalRewards = action.payload;
    },
    setAllRewards(state, action) {
      // Add this reducer
      state.allRewards = action.payload;
    },
  },
});

export const { setRewards, setTotalRewards, setAllRewards } =
  referralRewardsSlice.actions;
export default referralRewardsSlice.reducer;
