const priceFeedsByChainId = {
  1: {
    ETH: { name: "ETH", address: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419" },
    USDT: {
      name: "ETH",
      address: "0x3E7d1eAB13ad0104d2750B8863b489D65364e32D",
    },
    USDC: {
      name: "ETH",
      address: "0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6",
    },
    DAI: {
      name: "ETH",
      address: "0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9",
    },
  },
  42161: {
    ARB: { name: "ARB", address: "0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6" },
    USDT: {
      name: "ARB",
      address: "0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7",
    },

    USDC: {
      name: "ARB",
      address: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
    },
    DAI: {
      name: "ARB",
      address: "0xc5C8E77B397E531B8EC06BFb0048328B30E9eCfB",
    },
  },
  10: {
    // ETH: { name: "OP", address: "0x13e3Ee699D1909E989722E753853AE30b17e08c5" },
    OP: { name: "OP", address: "0x0D276FC14719f9292D5C1eA2198673d1f4269246" },
    USDT: { name: "OP", address: "0xECef79E109e997bCA29c1c0897ec9d7b03647F5E" },

    USDC: { name: "OP", address: "0x16a9FA2FDa030272Ce99B29CF780dFA30361E0f3" },
    DAI: {
      name: "OP",
      address: "0x8dBa75e83DA73cc766A7e5a0ee71F656BAb470d6",
    },
  },
  8453: {
    ETH: {
      name: "BASE",
      address: "0x71041dddad3595F9CEd3DcCFBe3D1F4b0a16Bb70",
    },
    USDT: {
      name: "BASE",
      address: "0xf19d560eB8d2ADf07BD6D13ed03e1D11215721F9",
    },
    USDC: {
      name: "BASE",
      address: "0x7e860098F58bBFC8648a4311b374B1D669a2bc6B",
    },
    DAI: {
      name: "BASE",
      address: "0x591e79239a7d679378eC8c847e5038150364C78F",
    },
  },
  56: {
    BNB: { name: "BSC", address: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE" },
    USDT: {
      name: "BSC",
      address: "0xB97Ad0E74fa7d920791E90258A6E2085088b4320",
    },
    USDC: {
      name: "BSC",
      address: "0x51597f405303C4377E36123cBc172b13269EA163",
    },
    DAI: {
      name: "BSC",
      address: "0x132d3C0B1D2cEa0BC552588063bdBb210FDeecfA",
    },
  },
  137: {
    MATIC: {
      name: "MATIC",
      address: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
    },
    USDT: {
      name: "MATIC",
      address: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
    },
    USDC: {
      name: "MATIC",
      address: "0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7",
    },
    ETH: {
      name: "MATIC",
      address: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    },
    BNB: {
      name: "MATIC",
      address: "0x82a6c4AF830caa6c97bb504425f6A66165C2c26e",
    },
    AVAX: {
      name: "MATIC",
      address: "0xe01eA2fbd8D76ee323FbEd03eB9a8625EC981A10",
    },
    ARB: {
      name: "MATIC",
      address: "0xb2A824043730FE05F3DA2efaFa1CBbe83fa548D6",
    },
    OP: {
      name: "MATIC",
      address: "0x0D276FC14719f9292D5C1eA2198673d1f4269246",
    },
    ZK: {
      name: "MATIC",
      address: "0xD1ce60dc8AE060DDD17cA8716C96f193bC88DD13",
    },
    DAI: {
      name: "MATIC",
      address: "0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D",
    },
    USD: {
      name: "MATIC",
      address: "0x0A6513e40db6EB1b165753AD52E80663aeA50545",
    },
    GBP: {
      name: "MATIC",
      address: "0x099a2540848573e94fb1Ca0Fa420b00acbBc845a",
    },
    EUR: {
      name: "MATIC",
      address: "0x73366Fe0AA0Ded304479862808e02506FE556a98",
    },
  },
  43114: {
    AVAX: {
      name: "AVAX",
      address: "0x0A77230d17318075983913bC2145DB16C7366156",
    },
    USDT: {
      name: "AVAX",
      address: "0xEBE676ee90Fe1112671f19b6B7459bC678B67e8a",
    },
    USDC: {
      name: "AVAX",
      address: "0xF096872672F44d6EBA71458D74fe67F9a77a23B9",
    },
    DAI: {
      name: "AVAX",
      address: "0x51D7180edA2260cc4F6e4EebB82FEF5c3c2B8300",
    },
  },
  324: {
    // ETH: {
    //   name: "ZKSYNC",
    //   address: "0x6D41d1dc818112880b40e26BD6FD347E41008eDA",
    // },
    ZK: {
      name: "ZKSYNC",
      address: "0xD1ce60dc8AE060DDD17cA8716C96f193bC88DD13",
    },
    USDT: {
      name: "ZKSYNC",
      address: "0xB615075979AE1836B476F651f1eB79f0Cd3956a9",
    },
    USDC: {
      name: "ZKSYNC",
      address: "0x1824D297C6d6D311A204495277B63e943C2D376E",
    },
    DAI: {
      name: "ZKSYNC",
      address: "0x5d336664b5D7A332Cd256Bf68CbF2270C6202fc6",
    },
  },
  // 81457: {
  //   ETH: {
  //     name: "BLAST",
  //     address: "0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612",
  //   },
  //   USDT: {
  //     name: "BLAST",
  //     address: "0x3f3f5dF88dC9F13eac63DF89EC16ef6e7E25DdE7",
  //   },
  //   USDCE: {
  //     name: "BLAST",
  //     address: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
  //   },
  //   USDC: {
  //     name: "BLAST",
  //     address: "0x50834F3163758fcC1Df9973b6e91f0F0F0434aD3",
  //   },
  // },
};

export default priceFeedsByChainId;
