export const CoinsIcon = () => (
  <svg width={20} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <style>{`.cls-1 { fill: #fff; stroke-width: 0; }`}</style>
    <ellipse className="cls-1" cx="13.48" cy="16.86" rx="13.48" ry="5.62" />
    <ellipse className="cls-1" cx="22.52" cy="5.62" rx="13.48" ry="5.62" />
    <path
      className="cls-1"
      d="M0,21.21s12.22,8.08,26.96,0v2.62s-1.24,5.42-13.48,5.42S0,24.27,0,24.27v-3.06Z"
    />
    <path
      className="cls-1"
      d="M0,27.96s12.22,8.08,26.96,0v2.62s-1.24,5.42-13.48,5.42S0,31.02,0,31.02v-3.06Z"
    />
    <path
      className="cls-1"
      d="M27.44,13.88s1.54.39,1.65,3.77c0,0,4.8-.35,6.67-3.9v-3.14s-3.41,2.49-8.32,3.28Z"
    />
    <path
      className="cls-1"
      d="M29.09,20.25v4.24s5.47-1,6.66-4.06v-3.01s-2.41,2.62-6.66,2.84Z"
    />
  </svg>
);
