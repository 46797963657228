import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";

// Hardcoding the environment for now
const isDevelopment = true; // Change this to `process.env.NODE_ENV !== 'production'` later

const store = configureStore({
  reducer: rootReducer,
  devTools: isDevelopment, // Enable Redux DevTools only in development
});

export default store;
