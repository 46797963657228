import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import useStoreReferralLink from "./useStoreReferralLink";
import { setReferralLink, setReferral } from "../reducers/referralSlice";
import { setReferrer, setReferrerAddress } from "../reducers/referrerSlice";
import { setNovaPoints } from "../reducers/novaPointsSlice";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const generateReferralLink = async (address) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(address);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  const refID = `stxnova-${hashHex.substring(0, 8)}`;
  const referralLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${refID}`;
  return referralLink;
};

const fetchExistingLink = async (walletAddress) => {
  try {
    const response = await fetch(
      `${
        process.env.REACT_APP_SERVER_URL
      }/referralLinkHandler?walletAddress=${encodeURIComponent(walletAddress)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
      }
    );

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      throw new Error(`Failed to fetch referral link: ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Invalid content-type. Expected application/json");
    }

    const data = await response.json();

    if (!data || !data.referral_id) {
      throw new Error("Invalid data received from server");
    }

    return data;
  } catch (error) {
    return null;
  }
};

const getReferrerAddress = async (referrerUrl) => {
  if (!referrerUrl) {
    return ZERO_ADDRESS;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({ referrerUrl }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.referrerAddress;
    } else {
      return ZERO_ADDRESS;
    }
  } catch (error) {
    return ZERO_ADDRESS;
  }
};

const useReferralLink = (address, isConnected) => {
  const dispatch = useDispatch();
  const referralLink = useSelector((state) => state.referral.referral);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { storeReferralLink } = useStoreReferralLink();
  const timeoutRef = useRef(null);

  useEffect(() => {
    const manageReferralLink = async () => {
      if (!isConnected || !address) {
        return;
      }

      if (referralLink) {
        return;
      }

      setLoading(true);
      try {
        const existingLink = await fetchExistingLink(address);
        if (existingLink && existingLink.referral_id) {
          const fullLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${existingLink.referral_id}`;
          dispatch(setReferralLink(fullLink));
          dispatch(setReferral(existingLink.referral_id));
          dispatch(setNovaPoints(existingLink.nova_points_rewards));
          if (existingLink.referrer_id) {
            dispatch(setReferrer(existingLink.referrer_id));
            const referrerAddresss = await getReferrerAddress(
              existingLink.referrer_id
            );
            dispatch(setReferrerAddress(referrerAddresss));
          } else {
            dispatch(setReferrer(null));
          }
          setLoading(false);
          return;
        }

        const newLink = await generateReferralLink(address);
        const newId = newLink.split("=")[1];
        if (newId) {
          const savedLink = await storeReferralLink(address, newId);
          const fullLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${savedLink}`;
          dispatch(setReferralLink(fullLink));
          dispatch(setReferral(savedLink));
          const referrerAddresss = await getReferrerAddress(
            existingLink?.referrer_id || ""
          );
          dispatch(setReferrerAddress(referrerAddresss));
        } else {
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      manageReferralLink();
    }, 500);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [address, isConnected, storeReferralLink, dispatch, referralLink]);

  const handleCopy = async () => {
    if (!referralLink) {
      return;
    }
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      setError("Failed to copy referral link: " + error.message);
    }
  };

  return { referralLink, copied, error, handleCopy, loading };
};

export default useReferralLink;
