import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import blockchainPriceFeeds from "../../../../utils/priceFeedsByChainId";
import {
  currencyIcons,
  specialCurrencyLabels,
  getDefaultCurrencies,
} from "../../../../utils/currenciesUtils";
import logoIcon from "../../../../assets/img/logo-icon-dark.png";
import nPoints from "../../../../assets/img/nova-points.svg";
import { useSelector, useDispatch } from "react-redux";
import { setDollarAmount } from "../../../../reducers/dollarSlice";
import {
  setSelectedCurrency,
  setSelectedFiat,
  initializeCurrency,
} from "../../../../reducers/currencySlice";
import {
  updateBalance,
  updateBalanceInDollar,
} from "../../../../reducers/balanceSlice";
import {
  setEthAmount,
  setSnovaAmount,
  setNovaPoints,
} from "../../../../reducers/amountsSlice";
import useNovaPoints, {
  calculateNovaPoints,
} from "../../../../hooks/useNovaPoints";
import { useTranslation } from "react-i18next";
import {
  useDebouncedValue,
  truncateToDecimalPlace,
  formatValue,
  validateAndFormatInput,
} from "../../../../utils/helpers";
import { useBlockchain } from "../../../../hooks/useBlockchain";
import useBalanceData from "../../../../hooks/useBalanceData";
import { debounce } from "../../../../utils/debounce";
import ConnectButton from "./../bannerSelectors/ConnectButton";
import "../banner.css";
import tokensByChainId from "../../../../utils/tokensByChainId";

const ethers = require("ethers");
const actualPricePerOneSNOVA = process.env.REACT_APP_ACTUAL_PRICE;
const maximumRoundSupplyInDollars = 288000;

export default function RatioCalculatorCrypto() {
  const { isConnected, chainId, address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const dispatch = useDispatch();

  const ethAmount = useSelector((state) => state.amounts.ethAmount);
  const snovaAmount = useSelector((state) => state.amounts.snovaAmount);
  const novaPoints = useSelector((state) => state.amounts.novaPoints);
  const dollarAmount = useSelector((state) => state.dollar.dollarAmount);
  const selectedCurrency = useSelector(
    (state) => state.currency.selectedCurrency
  );
  const selectedFiat = useSelector((state) => state.currency.selectedFiat);
  const CurrencyAmount = useSelector((state) => state.balance.CurrencyAmount);
  const version = useSelector((state) => state.balance.version);
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);

  const [dropInner, setDropInner] = useState(false);
  const [lastConnectedChainId, setLastConnectedChainId] = useState(null);
  const [inputSource, setInputSource] = useState(null);
  const [lastConnectedCurrencies, setLastConnectedCurrencies] = useState([]);
  const [currencyBalances, setCurrencyBalances] = useState({});
  const calculatedNovaPoints = useNovaPoints(parseFloat(dollarAmount));

  const dropdownRef = useRef(null);
  const ethAmountRef = useRef(ethAmount);
  const inputSourceRef = useRef(inputSource);

  useEffect(() => {
    ethAmountRef.current = ethAmount;
    inputSourceRef.current = inputSource;
  }, [ethAmount, inputSource]);

  const {
    normalizedPrice,
    prices,
    fetchNormalizedPrice,
    fetchAllPrices,
    fetchPriceForCurrency,
  } = useBlockchain({
    isConnected,
    chainId,
    walletProvider,
    selectedCurrency,
    selectedFiat,
    buyWithCard,
  });

  const [currentNormalizedPrice, setCurrentNormalizedPrice] =
    useState(normalizedPrice);
  const debouncedEthAmount = useDebouncedValue(ethAmount, 300);
  const debouncedSnovaAmount = useDebouncedValue(snovaAmount, 300);
  const { t } = useTranslation();

  useEffect(() => {
    fetchNormalizedPrice().then((price) => setCurrentNormalizedPrice(price));
  }, [selectedCurrency, fetchNormalizedPrice, chainId]);

  useEffect(() => {
    if (debouncedEthAmount && inputSource === "eth") {
      calculateSnova(parseFloat(debouncedEthAmount));
    } else if (debouncedSnovaAmount && inputSource === "snova") {
      calculateEth(parseFloat(debouncedSnovaAmount));
    }
  }, [debouncedEthAmount, debouncedSnovaAmount, normalizedPrice, inputSource]);

  useEffect(() => {
    if (CurrencyAmount) {
      dispatch(setEthAmount(CurrencyAmount));
      setInputSource("eth");
    }
  }, [CurrencyAmount, version, dispatch]);

  useEffect(() => {
    if (isConnected) {
      handleConnectedState(chainId);
    } else {
      handleDisconnectedState();
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    updateCurrencyOptions();
  }, [chainId, isConnected, selectedCurrency]);

  useEffect(() => {
    restorePreviousAmounts();
  }, []);

  useEffect(() => {
    saveAmountsToLocalStorage();
  }, [ethAmount, snovaAmount, inputSource]);

  useEffect(() => {
    recalculateValuesIfNeeded();
  }, [chainId, selectedCurrency, isConnected]);

  useEffect(() => {
    if (isConnected) {
      fetchCurrencyBalances();
    }
  }, [isConnected, selectedCurrency, chainId, prices]);

  const { balance, balanceInDollar, fetchBalanceAndPrice } = useBalanceData({
    isConnected,
    walletProvider,
    chainId,
    selectedCurrency,
    address,
    fetchPriceForCurrency,
  });

  useEffect(() => {
    dispatch(updateBalance(balance));
    dispatch(updateBalanceInDollar(balanceInDollar));
  }, [balance, balanceInDollar, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected) {
        await fetchAllPrices();
        await fetchCurrencyBalances();
        await fetchBalanceAndPrice();
      }
      if (inputSource !== "snova") {
        const updatedPrice = await fetchPriceForCurrency(selectedCurrency);
        if (
          updatedPrice !== null &&
          updatedPrice !== currentNormalizedPrice &&
          inputSource === "eth"
        ) {
          setCurrentNormalizedPrice(updatedPrice);
          debouncedSetDollarAmount(ethAmountRef.current * updatedPrice);
          calculateSnova();
        }
      } else {
        const updatedPrice = await fetchPriceForCurrency(selectedCurrency);
        if (
          updatedPrice !== null &&
          updatedPrice !== currentNormalizedPrice &&
          inputSource === "snova"
        ) {
          setCurrentNormalizedPrice(updatedPrice);
          debouncedSetDollarAmount(ethAmountRef.current * updatedPrice);
          calculateEth();
        }
      }
      const updatedDollarAmount = parseFloat(dollarAmount);
      const updatedNovaPoints = calculateNovaPoints(updatedDollarAmount);
      dispatch(setNovaPoints(updatedNovaPoints));
    };

    fetchData();
  }, [
    isConnected,
    selectedCurrency,
    chainId,
    fetchBalanceAndPrice,
    dollarAmount,
    dispatch,
  ]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropInner(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const debouncedSetDollarAmount = useCallback(
    debounce((amount) => {
      dispatch(setDollarAmount(amount));
    }, 325),
    [dispatch]
  );

  const toggleInner = async (event) => {
    event.stopPropagation();
    setDropInner((prev) => !prev);

    if (!dropInner) {
      await fetchAllPrices();
      await fetchCurrencyBalances();
    }
  };

  const handleCurrencyChange = (currency, event) => {
    event.stopPropagation();
    dispatch(setSelectedCurrency(currency));
    setDropInner(false);
  };

  const fetchCurrencyBalances = async () => {
    if (!isConnected) return;

    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const balances = {};

      const fetchBalance = async (currency) => {
        if (["ETH", "BNB", "MATIC", "AVAX"].includes(currency)) {
          const balance = await ethersProvider.getBalance(userAddress);
          return ethers.utils.formatEther(balance);
        } else {
          const tokenInfo = tokensByChainId[chainId]?.[currency];
          if (tokenInfo && tokenInfo.address) {
            const contract = new ethers.Contract(
              tokenInfo.address,
              ["function balanceOf(address owner) view returns (uint256)"],
              signer
            );
            const balance = await contract.balanceOf(userAddress);
            return balance / 10 ** tokenInfo.decimals;
          } else {
            // console.error("Unsupported chain ID or currency");// TO be uncomment later
            return null;
          }
        }
      };

      const currencies =
        chainId === 137
          ? ["MATIC", "USDT", "USDC", "DAI"]
          : Object.keys(
              blockchainPriceFeeds[chainId] || getDefaultCurrencies()
            );

      await Promise.all(
        currencies.map(async (currency) => {
          const balance = await fetchBalance(currency);
          if (balance !== null) {
            const price = prices[currency] || 0;
            const balanceInDollar = truncateToDecimalPlace(balance, 4) * price;
            balances[currency] = { balance, balanceInDollar };
          }
        })
      );

      setCurrencyBalances(balances);
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const getCryptoCurrencyOptions = () => {
    let currenciesToShow = {};
    const selectedChainId = isConnected ? chainId : lastConnectedChainId;
    const numericChainId = Number(selectedChainId);

    if (numericChainId && blockchainPriceFeeds[numericChainId]) {
      if (numericChainId === 137) {
        const allowedCurrencies = ["MATIC", "USDT", "USDC", "DAI"];
        currenciesToShow = Object.keys(blockchainPriceFeeds["137"])
          .filter((currency) => allowedCurrencies.includes(currency))
          .reduce((obj, key) => {
            obj[key] = blockchainPriceFeeds["137"][key];
            return obj;
          }, {});
      } else {
        currenciesToShow = blockchainPriceFeeds[numericChainId];
      }
    } else {
      currenciesToShow = getDefaultCurrencies().reduce((obj, currency) => {
        obj[currency] = { address: null };
        return obj;
      }, {});
    }

    return Object.keys(currenciesToShow).map((currency) => {
      const displayCurrency = specialCurrencyLabels[currency] || currency;
      const balanceData = currencyBalances[currency] || {};
      return (
        <div
          key={currency}
          onClick={(e) => handleCurrencyChange(currency, e)}
          className={`send__asset-select css-f6epcl dropdown ${
            selectedCurrency === currency ? "active" : ""
          }`}
        >
          <CurrencyOption
            currency={currency}
            currencyName={displayCurrency}
            balance={balanceData.balance}
            balanceInDollar={balanceData.balanceInDollar}
          />
        </div>
      );
    });
  };

  const CurrencyOption = ({
    currency,
    currencyName,
    balance,
    balanceInDollar,
  }) => (
    <div className="w-full grid grid-cols-fr items-center">
      <div className="css-70qvj9">
        <div className="css-1o3uuyz" style={{ margin: "0px 8px 0px 0px" }}>
          {typeof currencyIcons[currency] === "function" ? (
            React.createElement(currencyIcons[currency], {
              style: { width: "20px", height: "20px" },
              "aria-label": currency,
            })
          ) : (
            <img
              src={currencyIcons[currency]}
              alt={currency}
              style={{ width: "20px", height: "20px", objectFit: "cover" }}
            />
          )}
        </div>
        <div
          className="css-cgq59l"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <p className="text-[#F5F5F4] text-[14px] font-weight-bold">
            {currencyName}
          </p>
        </div>
      </div>
      {isConnected && (
        <>
          <div className="css-70qvj9 justify-center" style={{ width: "100%" }}>
            <div className="css-cgq59l" style={{ width: "47%" }}>
              <p
                className="text-[#F5F5F4] text-[14px] font-weight-bold"
                style={{ textAlign: "right" }}
              >
                {balance !== undefined ? formatValue(balance, 4) : "0.0000"}
              </p>
            </div>
          </div>
          <div className="css-70qvj9 justify-ends">
            <div className="css-cgq59l">
              <p className="text-[#F5F5F4] text-[14px] font-weight-bold">
                $
                {balanceInDollar !== undefined
                  ? formatValue(balanceInDollar, 2)
                  : "0.00"}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );

  const handleConnectedState = (chainId) => {
    if (!chainId) return;
    localStorage.setItem("lastConnectedChainId", chainId);
    setLastConnectedChainId(chainId);
    setLastConnectedCurrencies(Object.keys(blockchainPriceFeeds[chainId]));
    dispatch(initializeCurrency({ chainId }));
  };

  const handleDisconnectedState = () => {
    const storedChainId = localStorage.getItem("lastConnectedChainId");
    if (storedChainId && !isConnected) {
      setLastConnectedChainId(storedChainId);
      setLastConnectedCurrencies(
        Object.keys(blockchainPriceFeeds[storedChainId] || {})
      );
      dispatch(initializeCurrency({ chainId: storedChainId }));
    }
  };

  const updateCurrencyOptions = () => {
    const effectiveChainId = isConnected ? chainId : lastConnectedChainId;
    if (!effectiveChainId) return;

    let currencies = blockchainPriceFeeds[effectiveChainId] || {};
    if (effectiveChainId === 137) {
      const allowedCurrencies = ["MATIC", "USDT", "USDC", "DAI"];
      currencies = Object.keys(currencies)
        .filter((currency) => allowedCurrencies.includes(currency))
        .reduce((obj, key) => {
          obj[key] = currencies[key];
          return obj;
        }, {});
    } else if (!Object.keys(currencies).length) {
      getDefaultCurrencies().forEach((currency) => {
        currencies[currency] = { address: null };
      });
    }

    const previousSelectedCurrency = localStorage.getItem("selectedCurrency");
    if (!Object.keys(currencies).includes(selectedCurrency)) {
      dispatch(setSelectedCurrency(Object.keys(currencies)[0]));
    } else if (!previousSelectedCurrency) {
      dispatch(setSelectedCurrency(Object.keys(currencies)[0]));
    }
  };

  const restorePreviousAmounts = () => {
    const savedEthAmount = localStorage.getItem("ethAmount");
    const savedSnovaAmount = localStorage.getItem("snovaAmount");
    const savedInputSource = localStorage.getItem("inputSource");
    if (savedEthAmount !== null && savedInputSource === "eth") {
      dispatch(setEthAmount(savedEthAmount));
      setInputSource("eth");
    }
    if (savedSnovaAmount !== null && savedInputSource === "snova") {
      dispatch(setSnovaAmount(savedSnovaAmount));
      setInputSource("snova");
    }
  };

  const saveAmountsToLocalStorage = () => {
    if (ethAmount) {
      localStorage.setItem("ethAmount", ethAmount);
    } else {
      localStorage.removeItem("ethAmount");
    }
    if (snovaAmount) {
      localStorage.setItem("snovaAmount", snovaAmount);
    } else {
      localStorage.removeItem("snovaAmount");
    }
    if (inputSource) {
      localStorage.setItem("inputSource", inputSource);
    }
  };

  const recalculateValuesIfNeeded = () => {
    const savedInputSource = localStorage.getItem("inputSource");
    if (savedInputSource === "eth" && ethAmount) {
      calculateSnova(parseFloat(ethAmount));
    } else if (savedInputSource === "snova" && snovaAmount) {
      calculateEth(parseFloat(snovaAmount));
    }
  };

  const calculateSnova = useCallback(
    async (ethValue) => {
      if (inputSource !== "eth") return;
      try {
        const dollarAmount = ethValue * normalizedPrice;
        dispatch(setDollarAmount(dollarAmount));
        const snovaValue = formatValue(
          ethValue * (normalizedPrice / actualPricePerOneSNOVA),
          3
        );
        if (snovaValue * actualPricePerOneSNOVA > maximumRoundSupplyInDollars) {
          dispatch(
            setEthAmount(
              formatValue(maximumRoundSupplyInDollars / normalizedPrice, 4)
            )
          );
          dispatch(
            setSnovaAmount(
              formatValue(
                maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
                0
              )
            )
          );
        } else {
          dispatch(setSnovaAmount(snovaValue));
        }
      } catch (error) {
        console.error("Error calculating SNOVA:", error);
      }
    },
    [normalizedPrice, inputSource]
  );

  const calculateEth = useCallback(
    async (snovaValue) => {
      if (inputSource !== "snova") return;
      try {
        const dollarAmount =
          (snovaValue * 100 * actualPricePerOneSNOVA * 100) / 10000;
        dispatch(setDollarAmount(dollarAmount));

        const finalValue = normalizedPrice / actualPricePerOneSNOVA;

        if (isNaN(finalValue) || isNaN(snovaValue)) {
          throw new Error("Calculation resulted in NaN");
        }

        const ethValue = snovaValue / finalValue;

        let truncatedEthValue;
        if (ethValue * normalizedPrice > maximumRoundSupplyInDollars) {
          truncatedEthValue = formatValue(
            maximumRoundSupplyInDollars / normalizedPrice,
            4
          );

          if (truncatedEthValue === undefined) {
            throw new Error("truncateToDecimalPlace returned undefined");
          }

          dispatch(setEthAmount(truncatedEthValue));

          dispatch(
            setSnovaAmount(
              formatValue(
                maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
                0
              )
            )
          );
        } else {
          truncatedEthValue = formatValue(ethValue, 4);

          if (truncatedEthValue === undefined) {
            throw new Error("truncateToDecimalPlace returned undefined");
          }

          dispatch(setEthAmount(truncatedEthValue));
        }
      } catch (error) {
        console.error("Error calculating ETH:", error);
      }
    },
    [normalizedPrice, inputSource]
  );

  const handleEthAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, ethAmount, 4);
    dispatch(setEthAmount(formattedValue));
    setInputSource("eth");
    localStorage.setItem("inputSource", "eth");
    if (formattedValue) {
      calculateSnova(parseFloat(formattedValue));
    } else {
      dispatch(setSnovaAmount(""));
      dispatch(setDollarAmount(0));
    }
  };

  const handleSnovaAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, snovaAmount, 3);
    dispatch(setSnovaAmount(formattedValue));
    setInputSource("snova");
    localStorage.setItem("inputSource", "snova");
    if (formattedValue) {
      calculateEth(parseFloat(formattedValue));
    } else {
      dispatch(setEthAmount(""));
      dispatch(setDollarAmount(0));
    }
  };

  function truncateNumber(number, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
  }

  return (
    <div>
      <div className="css-1lekzkb">
        <div className="css-1p1m4ay">
          <p className="MuiTypography-root MuiTypography-body1 text-[14px] css-6hkpqo">
            {t("homePage.banner.balance")}
          </p>
          <ConnectButton
            buttonText={t("homePage.banner.connectYourWallet")}
            className="MuiTypography-root MuiTypography-body1 css-1enqu04"
            balance={balance}
            balanceInDollar={balanceInDollar}
          />
        </div>
      </div>
      <div className="flex flex-col laptop:flex-row justify-between gap-[4px] relative z-index-11">
        <style>
          {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }
        `}
        </style>
        <div className="css-1c5id9a">
          <div
            ref={dropdownRef}
            onClick={toggleInner}
            className="css-70qvj9"
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                typeof currencyIcons[selectedCurrency] === "string"
                  ? currencyIcons[selectedCurrency]
                  : ""
              }
              alt={specialCurrencyLabels[selectedCurrency] || selectedCurrency}
              className="css-vqypjx"
              style={{
                width: "24px",
                height: "24px",
                objectFit: "cover",
              }}
            />
            <p className="text-[14px] text-[#F5F5F4] mx-[8px] font-[500]">
              {specialCurrencyLabels[selectedCurrency] || selectedCurrency}
            </p>
            <div
              className="_arrow_1oajo_1"
              style={{
                transform: dropInner ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.3s ease",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 10L12 5.73613L4 5.73613L8 10Z"
                  fill="#A9A29D"
                />
              </svg>
            </div>
            {dropInner && (
              <div className="css-1r69ko0">{getCryptoCurrencyOptions()}</div>
            )}
          </div>
          <input
            type="text"
            placeholder="0"
            value={ethAmount}
            onChange={handleEthAmountChange}
            className="css-kxgh99"
          />
          <div className="dollar-text">${formatValue(dollarAmount, 2)}</div>
        </div>
        <div className="css-1c5id9a">
          <div className="css-70qvj9">
            <img src={logoIcon} className="buy-snova-icon" />
            <p className="MuiTypography-root MuiTypography-body1 css-eikm1t">
              SNOVA
            </p>
          </div>
          <input
            type="text"
            placeholder="0"
            value={snovaAmount}
            onChange={handleSnovaAmountChange}
            className="css-kxgh99"
          />
          <span
            className="dollar-text orange text-accent text-[20px] font-medium flex items-center gap-1 leading-[25px] mb-[-2px] sm:text-[16px]"
            translate="no"
          >
            <img
              src={nPoints}
              style={{ height: "25px", width: "20px", marginRight: "4px" }}
            />
            +{formatValue(calculatedNovaPoints, 0)}{" "}
            {t("homePage.novaBox.novaPoints")}
          </span>
        </div>
        <div className="css-im6gv3 for-mblonly">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M10 4.1665L10 14.9998M15 10.8332L10.5893 15.2439C10.2638 15.5694 9.73618 15.5694 9.41074 15.2439L5 10.8332"
              stroke="#79716B"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
