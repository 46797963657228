import React from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import useReferralLink from "../../../../hooks/useReferralLink";
import { useTranslation } from "react-i18next";
import done from "../../../../assets/img/done1.svg";
import copy from "../../../../assets/img/copy1.svg";
import wallet from "../../../../assets/img/connect-wallet.svg";
import ConnectButton from "../../../Home/Banner/bannerSelectors/ConnectButton";
import ReferralLinkShimmer from "../ReferralLinkShimmer";

const ReferralLink = () => {
  const { address, isConnected } = useWeb3ModalAccount();
  const { referralLink, copied, error, handleCopy } = useReferralLink(
    address,
    isConnected
  );
  const { t } = useTranslation();

  // Check if referralLink is undefined or empty and display shimmer
  if (!isConnected) {
    return (
      <ConnectButton
        type="button"
        className="ant-btn flex jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
        style={{
          width: "100%",
          backgroundColor: "#fc6432",
          color: "rgb(255, 255, 255)",
          boxShadow: "6px 6px #C04000",
        }}
        buttonText={t("Header.connectWallet")}
      >
        {/* <img src={wallet} className="wallet-icon" /> */}
        <svg
          width={20}
          className="wallet-icon no-tp"
          viewBox="0 0 16 15"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7996 0H1.60054V0.8H0.800537V0H0.8V0.799805H0V0.8H0.8V1.5998H0V12.7996H0.8L0.8 13.5996H0V13.6004H0.8L0.8 14.4H0V14.4004H0.8V14.4H0.800537V14.4004H1.60054V14.4H12.7996V14.4004H13.5996V14.4H13.6004V14.4004H14.4004V13.6004H14.4V13.5996H14.4004V12.7996H14.4V11.2002H15.1995V11.2H15.2V11.2002H16V11.2H15.2V10.4004H16V10.4002H15.2V9.60039H16V4.8H15.2V4.0002H16V4H15.2V3.2002H16V3.2H14.4V1.5998H14.4004V0.8H14.4V0.799805H14.4004V0H14.4V0.799805H13.6004V0H13.5996V0.8H12.7996V0ZM14.4 0.8H13.6004V1.5998H14.4V0.8ZM15.1995 11.2V10.4002H14.3995V11.2002H14.4V11.2H15.1995ZM14.4 12.7996H13.6004V13.5996H14.4V12.7996ZM14.4 13.6004H13.6004V14.4H14.4V13.6004ZM13.5996 14.4V13.6004H12.7996V14.4H13.5996ZM1.60054 14.4V13.6004H0.800537L0.800537 14.4H1.60054ZM12.8 11.2V12.8H1.6V1.6H12.8V3.2H6.4V11.2H12.8ZM14.4 9.6H8V4.8H14.4V9.6ZM11.2 6.4H9.6V8H11.2V6.4ZM15.1995 4.0002V3.2002H14.3995V4.0002H15.1995Z"
          />
        </svg>
        <span className="btn-buy-token"></span>
      </ConnectButton>
    );
  }

  if (!referralLink) {
    return <ReferralLinkShimmer />;
  }

  return (
    <div
      className="_copyWrapper_1fjrj_1"
      aria-label={t("referralPage.banner.copiedToClipboard")}
    >
      <span className="_copyText_1fjrj_12">
        {referralLink ||
          /*t("referralPage.banner.noReferralLinkAvailable")*/ "Loading.."}
      </span>
      {copied ? (
        <div className="_copyBtn_1fjrj_20">
          <img src={done} alt="Copied" />
        </div>
      ) : (
        referralLink && (
          <button
            className="_copyBtn_1fjrj_20"
            onClick={() => handleCopy(referralLink)}
          >
            <img src={copy} alt="Copy" />
          </button>
        )
      )}
    </div>
  );
};

export default ReferralLink;
