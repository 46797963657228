// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { ethers } from "ethers";
// import tokensByChainId from "../utils/tokensByChainId";
// import priceFeedsByChainId from "../utils/priceFeedsByChainId";
// import providersByChainId from "../utils/providersByChainId";
// import { registryAddressesByChainId } from "../utils/contractAddressesByChainId";
// import { setAllRewards } from "../reducers/referralRewardsSlice";

// const chainIds = [1, 10, 56, 137, 324, 8453, 42161, 43114];

// const REGISTRY_ABI = [
//   "function refBalanceOf(address user, address token) external view returns (uint256)",
//   "function getFundsWallet() external view returns (address)",
// ];

// const PRICE_FEED_ABI = [
//   "function latestRoundData() external view returns (uint80 roundId, int256 answer, uint256 startedAt, uint256 updatedAt, uint80 answeredInRound)",
// ];

// const mockRewardsData = {
//   ETH: { amount: "1040000000000000000", usdValue: "3000000000000000000000" }, // 1 ETH, $3000
//   USDT: { amount: "1000000000000000000", usdValue: "1000000000000000000" }, // 1 USDT, $1
//   USDC: { amount: "1000000000000000000", usdValue: "1000000000000000000" }, // 1 USDC, $1
//   DAI: { amount: "1000000000000000000", usdValue: "1000000000000000000" }, // 1 DAI, $1
//   BNB: { amount: "1000000000000000000", usdValue: "300000000000000000000" }, // 1 BNB, $300
//   MATIC: { amount: "1000000000000000000", usdValue: "2000000000000000000" }, // 1 MATIC, $2
//   ARB: { amount: "1000000000000000000", usdValue: "1500000000000000000" }, // 1 ARB, $1.5
//   AVAX: { amount: "1000000000000000000", usdValue: "30000000000000000000" }, // 1 AVAX, $30
//   OP: { amount: "1000000000000000000", usdValue: "1000000000000000000" }, // 1 OP, $1
//   ZK: { amount: "1000000000000000000", usdValue: "500000000000000000" }, // 1 ZK, $0.5
// };

// // Function to test if a provider is working
// const testProvider = async (provider) => {
//   try {
//     await provider.getBlockNumber();
//     return true;
//   } catch (error) {
//     console.warn(`Provider test failed:`, error);
//     return false;
//   }
// };

// const useFetchAllReferralRewards = (address) => {
//   const dispatch = useDispatch();
//   const allRewards = useSelector((state) => state.referralRewards.allRewards);

//   useEffect(() => {
//     if (!address) return;

//     const fetchRewardsForAllChains = async () => {
//       try {
//         // console.log("Fetching rewards for all chains");

//         let allRewards = {};

//         for (const chainId of chainIds) {
//           //   console.log(`Fetching rewards for chain ID: ${chainId}`);
//           const tokenAddresses = tokensByChainId[chainId] || {};
//           const priceFeeds = priceFeedsByChainId[chainId] || {};
//           const ethersProviders = providersByChainId[chainId];
//           const contractAddress = registryAddressesByChainId[chainId];

//           if (!ethersProviders || !contractAddress) {
//             console.warn(
//               `No provider or contract address for chain ID: ${chainId}`
//             );
//             continue;
//           }

//           let contract;
//           let validProvider;
//           for (const provider of ethersProviders) {
//             const isProviderValid = await testProvider(
//               new ethers.providers.JsonRpcProvider(provider)
//             );
//             if (isProviderValid) {
//               validProvider = new ethers.providers.JsonRpcProvider(provider);
//               break;
//             }
//           }

//           if (!validProvider) {
//             console.error(`All providers failed for chain ID: ${chainId}`);
//             continue;
//           }

//           contract = new ethers.Contract(
//             contractAddress,
//             REGISTRY_ABI,
//             validProvider
//           );

//           const rewardsPerToken = await calculateRewards(
//             address,
//             tokenAddresses,
//             priceFeeds,
//             contract,
//             validProvider
//           );

//           //   console.log(`Rewards for chain ID ${chainId}:`, rewardsPerToken);
//           allRewards[chainId] = rewardsPerToken;
//         }

//         dispatch(setAllRewards(allRewards));
//         // console.log("All rewards fetched and dispatched:", allRewards);
//       } catch (error) {
//         console.error(
//           "Failed to fetch referral rewards for all chains:",
//           error
//         );
//       }
//     };

//     fetchRewardsForAllChains();
//   }, [address, dispatch]);

//   //   useEffect(() => {
//   //     if (!address) return;

//   //     const fetchRewardsForAllChains = async () => {
//   //       try {
//   //         console.log("Fetching rewards for all chains");

//   //         let allRewards = {};

//   //         for (const chainId of chainIds) {
//   //           console.log(`Fetching rewards for chain ID: ${chainId}`);
//   //           const tokenAddresses = tokensByChainId[chainId] || {};
//   //           const rewardsPerToken = {};

//   //           // Use mock data
//   //           for (const tokenKey in tokenAddresses) {
//   //             console.log(`Fetching reward for token: ${tokenKey}`);
//   //             rewardsPerToken[tokenKey] = mockRewardsData[tokenKey] || {
//   //               amount: "0",
//   //               usdValue: "0",
//   //             };
//   //             console.log(
//   //               `Mock reward for token ${tokenKey}:`,
//   //               rewardsPerToken[tokenKey]
//   //             );
//   //           }

//   //           allRewards[chainId] = rewardsPerToken;
//   //         }

//   //         dispatch(setAllRewards(allRewards));
//   //         console.log("All rewards fetched and dispatched:", allRewards);
//   //       } catch (error) {
//   //         console.error(
//   //           "Failed to fetch referral rewards for all chains:",
//   //           error
//   //         );
//   //       }
//   //     };

//   //     fetchRewardsForAllChains();
//   //   }, [address, dispatch]); //to be deleted later

//   return { allRewards };
// };

// const calculateRewards = async (
//   address,
//   tokenAddresses,
//   priceFeeds,
//   contract,
//   ethersProvider
// ) => {
//   const rewardsPerToken = {};

//   for (const tokenKey in tokenAddresses) {
//     // console.log(`Fetching reward for token: ${tokenKey}`);
//     const tokenAddress = tokenAddresses[tokenKey].address;
//     try {
//       const price = await fetchTokenPrice(tokenKey, priceFeeds, ethersProvider);
//       let reward;

//       if (tokenKey == "USDT" || tokenKey == "USDC") {
//         reward = (await contract.refBalanceOf(address, tokenAddress)).mul(1e12);
//       } else {
//         reward = await contract.refBalanceOf(address, tokenAddress);
//       }
//       const rewardInUsd = reward.mul(price).div(ethers.BigNumber.from(1e8));
//       console.log(tokenAddresses[tokenKey], "HGJFIIHGJF");
//       console.log(reward, rewardInUsd);
//       rewardsPerToken[tokenKey] = {
//         amount: reward.toString(),
//         usdValue: rewardInUsd.toString(),
//       };

//       //   console.log(`Reward for token ${tokenKey}:`, rewardsPerToken[tokenKey]);
//     } catch (error) {
//       console.error(`Failed to fetch reward for token ${tokenKey}:`, error);
//     }
//   }

//   return rewardsPerToken;
// };

// const fetchTokenPrice = async (tokenKey, priceFeeds, ethersProvider) => {
//   const defaultPrice = ethers.BigNumber.from(1).mul(ethers.BigNumber.from(1e8)); // 1$ with 8 decimals
//   if (!["ETH", "BNB", "MATIC", "AVAX"].includes(tokenKey)) {
//     return defaultPrice;
//   }

//   const priceFeedAddress = priceFeeds[tokenKey]?.address;
//   if (!priceFeedAddress) {
//     return defaultPrice;
//   }

//   try {
//     const priceFeedContract = new ethers.Contract(
//       priceFeedAddress,
//       PRICE_FEED_ABI,
//       ethersProvider
//     );
//     const [, answer] = await priceFeedContract.latestRoundData();
//     return ethers.BigNumber.from(answer);
//   } catch (error) {
//     console.error(`Failed to fetch price for ${tokenKey}:`, error);
//     return defaultPrice;
//   }
// };

// export default useFetchAllReferralRewards;
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllRewards } from "../reducers/referralRewardsSlice";
import { setReferralCount } from "../reducers/referralCountSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { debounce } from "../utils/debounce";
import tokensByChainId from "../utils/tokensByChainId";

const useFetchAllReferralRewards = () => {
  const dispatch = useDispatch();
  const { address } = useWeb3ModalAccount();
  const allRewards = useSelector((state) => state.referralRewards.allRewards);

  const fetchPrices = async () => {
    const tokenMap = {
      ETH: "ethereum",
      BNB: "binancecoin",
      MATIC: "polygon",
      AVAX: "avalanche-2",
      ARB: "arbitrum",
      OP: "optimism",
      ZK: "zk-rollups",
    };

    let prices = {};
    let fetchSuccess = false;

    // try {
    //   const response = await fetch("/.netlify/functions/fetchPrices");
    //   if (response.ok) {
    //     prices = await response.json();
    //     // console.log(
    //     //   "Prices fetched from CoinGecko through Netlify function:",
    //     //   prices
    //     // );
    //     fetchSuccess = true;
    //   } else {
    //     console.error(
    //       "CoinGecko API call through Netlify function failed with status:",
    //       response.status
    //     );
    //   }
    // } catch (error) {
    //   console.error(
    //     "Error fetching from CoinGecko through Netlify function:",
    //     error.message
    //   );
    // }

    // if (!fetchSuccess) {
    try {
      const response = await fetch(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,BNB,MATIC,AVAX,ARB,OP,ZK&tsyms=USD"
      );
      if (response.ok) {
        const cryptoComparePrices = await response.json();
        prices = Object.keys(tokenMap).reduce((acc, key) => {
          acc[tokenMap[key]] = { usd: cryptoComparePrices[key].USD };
          return acc;
        }, {});
        //   console.log("Prices fetched from CryptoCompare:", prices);
        fetchSuccess = true;
      } else {
        console.error(
          "CryptoCompare API call failed with status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error fetching from CryptoCompare:", error.message);
    }
    // }

    if (!fetchSuccess) {
      throw new Error("Failed to fetch prices from both APIs");
    }

    return prices;
  };

  const fetchReferralRewards = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralRewards`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { pending_rewards, active_referrals } = data;
        // console.log("Referral rewards fetched:", pending_rewards);

        const prices = await fetchPrices();

        const tokenMap = {
          ETH: "ethereum",
          BNB: "binancecoin",
          MATIC: "polygon",
          AVAX: "avalanche-2",
          ARB: "arbitrum",
          OP: "optimism",
          ZK: "zk-rollups",
        };

        let formattedRewards = {};
        Object.keys(tokensByChainId).forEach((chainId) => {
          formattedRewards[chainId] = {};
          Object.keys(tokensByChainId[chainId]).forEach((currency) => {
            const token = tokensByChainId[chainId][currency];
            const price = ["USDT", "USDC", "DAI"].includes(currency)
              ? 1
              : prices[tokenMap[currency.toUpperCase()]]?.usd || 0; // Static price of 1 for stablecoins

            const reward = pending_rewards
              .find((reward) => reward.network === chainId)
              ?.currencies.find((curr) => curr.currency === currency);

            if (reward) {
              const usdValue = reward.amount * price;
              //   console.log(`Reward for ${chainId} - ${currency}:`, reward);
              //   console.log(`Price for ${currency}:`, price);
              //   console.log(`USD Value for ${chainId} - ${currency}:`, usdValue);
              formattedRewards[chainId][currency] = {
                amount: reward.amount.toString(), // Store amount as string
                usdValue: usdValue.toString(), // Store USD value as string
              };
            } else {
              formattedRewards[chainId][currency] = {
                amount: "0",
                usdValue: "0",
              };
            }
          });
        });

        // console.log("Formatted rewards:", formattedRewards);
        dispatch(setAllRewards(formattedRewards));

        const activeReferralCount = active_referrals.length;
        dispatch(setReferralCount(activeReferralCount));
      } else {
        const errorData = await response.json();
        console.error("Error fetching referral rewards:", errorData.error);
      }
    } catch (error) {
      console.error("Error fetching referral rewards:", error.message);
    }
  };

  const debouncedFetchReferralRewards = useCallback(
    debounce(fetchReferralRewards, 300),
    [address, dispatch]
  );

  useEffect(() => {
    if (!address) return;
    debouncedFetchReferralRewards();
  }, [address, debouncedFetchReferralRewards]);

  return { allRewards, fetchReferralRewards };
};

export default useFetchAllReferralRewards;
