import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { setReferrer, setReferrerAddress } from "../reducers/referrerSlice";

const useReferrer = () => {
  const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
  const dispatch = useDispatch();
  const { isConnected } = useWeb3ModalAccount();
  const referrer = useSelector((state) => state.referrer.referrer);

  const getReferrerAddress = async (referrerUrl) => {
    if (!referrerUrl) {
      return ZERO_ADDRESS;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ referrerUrl }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.referrerAddress;
      } else {
        return ZERO_ADDRESS;
      }
    } catch (error) {
      return ZERO_ADDRESS;
    }
  };

  useEffect(() => {
    const checkReferrer = async () => {
      // Step 1: Check URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const referrerParam = urlParams.get("ref");
      if (referrerParam) {
        localStorage.setItem("referrer", referrerParam);
        dispatch(setReferrer(referrerParam));

        // Fetch and set referrer address
        let referrerAddress = ZERO_ADDRESS;
        while (referrerAddress === ZERO_ADDRESS) {
          referrerAddress = await getReferrerAddress(referrerParam);
        }
        dispatch(setReferrerAddress(referrerAddress));

        return;
      }

      // Step 2: Check local storage
      const storedReferrer = localStorage.getItem("referrer");
      if (storedReferrer) {
        dispatch(setReferrer(storedReferrer));

        // Fetch and set referrer address
        let referrerAddress = ZERO_ADDRESS;
        while (referrerAddress === ZERO_ADDRESS) {
          referrerAddress = await getReferrerAddress(storedReferrer);
        }
        dispatch(setReferrerAddress(referrerAddress));

        return;
      }
    };

    checkReferrer();
  }, [dispatch]);

  return referrer;
};

export default useReferrer;
