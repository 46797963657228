import { createSlice } from "@reduxjs/toolkit";
const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const initialState = {
  referrer: null,
  referrerAddress: ZERO_ADDRESS,
};

const referrerSlice = createSlice({
  name: "referrer",
  initialState,
  reducers: {
    setReferrer: (state, action) => {
      state.referrer = action.payload;
    },
    setReferrerAddress: (state, action) => {
      state.referrerAddress = action.payload;
    },
  },
});

export const { setReferrer, setReferrerAddress } = referrerSlice.actions;

export default referrerSlice.reducer;
