export const networks = {
  eth: {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
  bsc: {
    chainId: 56,
    name: "BNB Smart Chain Mainnet",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed1.bnbchain.org",
  },
  plg: {
    chainId: 137,
    name: "Polygon",
    currency: "MATIC",
    explorerUrl: "https://polygonscan.com",
    rpcUrl: "https://polygon-rpc.com/",
  },
  arb: {
    chainId: 42161,
    name: "Arbitrum One",
    currency: "ETH",
    explorerUrl: "https://arbiscan.io",
    rpcUrl: "https://arb1.arbitrum.io/rpc",
  },
  avax: {
    chainId: 43114,
    name: "Avalanche C-Chain",
    currency: "AVAX",
    explorerUrl: "https://snowtrace.io",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  },
  op: {
    chainId: 10,
    name: "OP Mainnet",
    currency: "ETH",
    explorerUrl: "https://optimistic.etherscan.io",
    rpcUrl: "https://mainnet.optimism.io",
  },
  base: {
    chainId: 8453,
    name: "Base",
    currency: "ETH",
    explorerUrl: "https://basescan.org",
    rpcUrl: "https://mainnet.base.org/",
  },
  zkSync: {
    chainId: 324,
    name: "zkSync Mainnet",
    currency: "ETH",
    explorerUrl: "https://explorer.zksync.io",
    rpcUrl: "https://mainnet.era.zksync.io",
  },
  // blast: {
  //   chainId: 81457,
  //   name: "Blast",
  //   currency: "ETH",
  //   explorerUrl: "https://blastscan.io",
  //   rpcUrl: "https://rpc.blast.io",
  // },
};

export const metadata = {
  name: "Stacknova AI",
  description: "The World's First AI-Powered Launchpad on Bitcoin.",
  url: "https://stacknova.netlify.app/",
  icons: ["https://ibb.co/FJyfW1M"],
};
