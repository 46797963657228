import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import {
  setApprovedCurrency,
  setApprovedAmount,
} from "../reducers/approvalSlice";
import { presaleAddressesByChainId } from "../utils/contractAddressesByChainId";
import tokensByChainId from "../utils/tokensByChainId";
import { debounce } from "../utils/debounce";

// List of native currencies
const NATIVE_CURRENCIES = ["ETH", "MATIC", "BNB", "AVAX"];

const ERC20_ABI = [
  "function allowance(address owner, address spender) external view returns (uint256)",
];

const useCheckApproval = (currency, amount) => {
  const dispatch = useDispatch();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, address } = useWeb3ModalAccount();

  const checkApprovalStatus = useCallback(
    debounce(async () => {
      if (!address || !walletProvider || !currency || !amount || !chainId)
        return;

      try {
        // If the currency is a native currency, skip the allowance check
        if (NATIVE_CURRENCIES.includes(currency)) {
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: true })
          );
          dispatch(
            setApprovedAmount({
              chainId,
              amount: ethers.utils.parseUnits(amount.toString(), 18),
            })
          );
          return;
        }

        const provider = new ethers.providers.Web3Provider(walletProvider);
        const tokenAddress = tokensByChainId[chainId]?.[currency]?.address;
        const spenderAddress = presaleAddressesByChainId[chainId];

        if (!tokenAddress || !spenderAddress) {
          return;
        }

        const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        const allowance = await contract.allowance(address, spenderAddress);
        const requiredAmount = ethers.utils.parseUnits(
          amount.toString(),
          tokensByChainId[chainId][currency].decimals
        );

        if (ethers.BigNumber.from(allowance).gte(requiredAmount)) {
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: true })
          );
          dispatch(
            setApprovedAmount({ chainId, amount: requiredAmount.toString() })
          );
        } else {
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: false })
          );
          dispatch(setApprovedAmount({ chainId, amount: "0" }));
        }
      } catch (error) {
        console.error("Failed to check approval status:", error);
      }
    }, 300),
    [address, walletProvider, currency, amount, chainId, dispatch]
  );

  useEffect(() => {
    checkApprovalStatus();
  }, [address, walletProvider, currency, amount, chainId, checkApprovalStatus]);

  return checkApprovalStatus;
};

export default useCheckApproval;
