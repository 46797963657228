import { useState, useEffect } from "react";

const useFetchTopUsersByRewards = () => {
  const [topUsers, setTopUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/getTopUsersByRewards`
        );
        if (response.ok) {
          // console.log("RESPONSE TOP USERS");
          // console.log(response);
          // console.log("RESPONSE TOP USERS");
          const data = await response.json();
          setTopUsers(data);
        } else {
          const errorData = await response.json();
          setError(errorData.error);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTopUsers();
  }, []);

  return { topUsers, loading, error };
};

export default useFetchTopUsersByRewards;
