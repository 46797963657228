import cross from "../../../assets/img/cross.svg";

import governanceIcon from "../../../assets/img/governance-icon.png";
import launchpadIcon from "../../../assets/img/launchpad-icon.png";
import aiTools from "../../../assets/img/ai-tools-icon.png";
import bridgeIcon from "../../../assets/img/bridge-icon.png";

import cardBg2 from "../../../assets/img/cardBg2.png";
import cardBg3 from "../../../assets/img/cardBg3.png";
import cardBg4 from "../../../assets/img/cardBg4.png";
import Vectors_Wrapper from "../../../assets/img/Vectors-Wrapper.svg";
import learnMore from "../../../assets/img/learn-more.svg";
import learnMoreBg from "../../../assets/img/learn-more-bg.svg";
import React, { useState } from "react";
import "./OurThesis.css";
import cardBg from "../../../assets/img/main.png";
import ReactFlipCard from "reactjs-flip-card";
import ecosystemFade from "../../../assets/img/snova-ecosystem-fade.png";
import { useTranslation } from "react-i18next";
const OurThesis = () => {
  const [isRotated, setIsRotated] = useState(false);

  const { t } = useTranslation();
  const handleCardClick = () => {
    setIsRotated(!isRotated);
  };
  const styles = {
    card: { borderRadius: 20 },
  };
  return (
    <section className="section-2024 relative ">
      {/*
      <img
        className="absolute w-[400px] left-[-180px] top-[-200px] md:w-[500px] md:left-[-150px] md:top-[-250px] lg:w-[700px] lg:left-[-100px] lg:top-[-200px] xl:w-[833px] xl:left-[-0px] xl:top-[-250px] 2xl:w-[833px] 2xl:left-[-0px] 2xl:top-[-250px]"
        src={ecosystemFade}
        alt="orderly-network-main-why-choose-orderly-background"
      />
      */}
      <div className="container-medium-thesis w-container max-width">
        <div
          data-w-id="5663c8a0-5d0e-0cfc-0c22-bf5f013a89a0"
          style={{
            transform:
              "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            opacity: 1,
            transformStyle: "preserve-3d",
          }}
          className="inner-container"
        >
          <div className="SectionHeaderV2_sectionHeaderSubtitle__eUmZ_ mb34 text-center">
            {t("homePage.ourThesis.heading")}
          </div>
          {/*We envision a near-future powered by cutting-edge AI tools and blockchain security, creating a comprehensive and accessible DeFi platform on Bitcoin.*/}
          {/*We envision a future powered by AI and blockchain security, shaping an accessible, comprehensive DeFi platform on Bitcoin.*/}
          {/*Envisioning a  Unified Future: AI-Enhanced DeFi on Bitcoin's Network.*/}
        </div>

        <div className="flex-cards">
          <ReactFlipCard
            flipTrigger="onClick"
            frontStyle={styles.card}
            backStyle={styles.card}
            frontComponent={
              <div className="single-card">
                <div className="content-card">
                  <h3 className="area-heading">
                    {t("homePage.ourThesis.aiTools")}
                  </h3>
                  <p className="paragraph-list ecosystem-features">
                    {t("homePage.ourThesis.advancedAnalytics")}
                    <br />
                    {t("homePage.ourThesis.investorInsights")}
                    <br />
                    {t("homePage.ourThesis.riskAssessment")}
                    <br />
                    {t("homePage.ourThesis.marketPrediction")}
                    <br />
                    {t("homePage.ourThesis.transparentTracking")}
                  </p>
                </div>
                <div className="btn-main-card">
                  <img
                    src={learnMoreBg}
                    loading="lazy"
                    width={200}
                    height={48}
                    alt
                    className="vectors-wrapper"
                    style={{}}
                  />
                  <div className="button-text">
                    {t("homePage.ourThesis.learnMore")}
                  </div>
                  <img
                    src={learnMore}
                    loading="lazy"
                    alt="Explore more"
                    className="plus-image"
                  />
                </div>
                {/* <div className="abs-img">
          <img src={cardBg2} alt="" />
        </div> */}
              </div>
            }
            backComponent={
              <div className="single-card">
                <div className="content-card">
                  <img src={cross} className="ext-btn" alt="" />
                  <img src={aiTools} className="areas-image" alt="" />
                  <div className="focus-modal-contents">
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.idoChallenges")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.idoChallengesContent")}
                    </p>
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.stacknovaAISolutions")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.stacknovaAISolutionsContent")}
                    </p>
                  </div>
                </div>
              </div>
            }
          />
          <ReactFlipCard
            flipTrigger="onClick"
            frontStyle={styles.card}
            backStyle={styles.card}
            frontComponent={
              <div className="single-card">
                <div className="content-card">
                  <h3 className="area-heading">
                    {t("homePage.ourThesis.launchpad")}
                  </h3>
                  <p className="paragraph-list ecosystem-features">
                    {t("homePage.ourThesis.aiEnhancedIdos")}
                    <br />
                    {t("homePage.ourThesis.insuranceProtection")}
                    <br />
                    {t("homePage.ourThesis.equitableDistribution")}
                    <br />
                    {t("homePage.ourThesis.postLaunchStability")}
                    <br />
                    {t("homePage.ourThesis.rigorousVetting")}
                  </p>
                </div>
                <div className="btn-main-card">
                  <img
                    src={learnMoreBg}
                    loading="lazy"
                    width={200}
                    height={48}
                    alt
                    className="vectors-wrapper"
                    style={{}}
                  />
                  <div className="button-text">
                    {t("homePage.ourThesis.learnMore")}
                  </div>
                  <img
                    src={learnMore}
                    loading="lazy"
                    alt="Explore more"
                    className="plus-image"
                  />
                </div>
                {/* <div className="abs-img">
          <img src={cardBg2} alt="" />
        </div> */}
              </div>
            }
            backComponent={
              <div className="single-card">
                <div className="content-card">
                  <img src={cross} className="ext-btn" alt="" />
                  <img src={launchpadIcon} className="areas-image" alt="" />
                  <div className="focus-modal-contents">
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.idoChallenges")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.idoChallengesContent")}
                    </p>
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.stacknovaIdoSolutions")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.stacknovaIdoSolutionsContent")}
                    </p>
                  </div>
                </div>
              </div>
            }
          />
          <ReactFlipCard
            flipTrigger="onClick"
            frontStyle={styles.card}
            backStyle={styles.card}
            frontComponent={
              <>
                <div className="single-card">
                  <div className="content-card">
                    <h3 className="area-heading">
                      {t("homePage.ourThesis.governance")}
                    </h3>
                    <p className="paragraph-list ecosystem-features">
                      {t("homePage.ourThesis.communityDriven")}
                      <br />
                      {t("homePage.ourThesis.daoIntegration")}
                      <br />
                      {t("homePage.ourThesis.inclusiveVoting")}
                      <br />
                      {t("homePage.ourThesis.aiAssistedAnalysis")}
                      <br />
                      {t("homePage.ourThesis.operationalClarity")}
                    </p>
                  </div>
                  <div className="btn-main-card">
                    <img
                      src={learnMoreBg}
                      loading="lazy"
                      width={200}
                      height={48}
                      alt
                      className="vectors-wrapper"
                      style={{}}
                    />
                    <div className="button-text">
                      {t("homePage.ourThesis.learnMore")}
                    </div>
                    <img
                      src={learnMore}
                      loading="lazy"
                      alt="Explore more"
                      className="plus-image"
                    />
                  </div>
                  {/* <div className="abs-img">
            <img src={cardBg2} alt="" />
          </div> */}
                </div>
              </>
            }
            backComponent={
              <div className="single-card">
                <div className="content-card">
                  <img src={cross} className="ext-btn" alt="" />
                  <img src={governanceIcon} className="areas-image" alt="" />
                  <div className="focus-modal-contents">
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.governanceChallenges")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.governanceChallengesContent")}
                    </p>
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.stacknovaDaoSolution")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.stacknovaDaoSolutionContent")}
                    </p>
                  </div>
                </div>
              </div>
            }
          />
          <ReactFlipCard
            flipTrigger="onClick"
            frontStyle={styles.card}
            backStyle={styles.card}
            frontComponent={
              <div className="single-card">
                <div className="content-card">
                  <h3 className="area-heading">
                    {t("homePage.ourThesis.bridge")}
                  </h3>
                  <p className="paragraph-list ecosystem-features">
                    {t("homePage.ourThesis.interoperability")}
                    <br />
                    {t("homePage.ourThesis.assetTransfer")}
                    <br />
                    {t("homePage.ourThesis.liquidityPools")}
                    <br />
                    {t("homePage.ourThesis.crossChain")}
                    <br />
                    {t("homePage.ourThesis.secureTransactions")}
                  </p>
                </div>
                <div className="btn-main-card">
                  <img
                    src={learnMoreBg}
                    loading="lazy"
                    width={200}
                    height={48}
                    alt
                    className="vectors-wrapper"
                    style={{}}
                  />
                  <div className="button-text">
                    {t("homePage.ourThesis.learnMore")}
                  </div>
                  <img
                    src={learnMore}
                    loading="lazy"
                    alt="Explore more"
                    className="plus-image"
                  />
                </div>
                {/* <div className="abs-img">
          <img src={cardBg2} alt="" />
        </div> */}
              </div>
            }
            backComponent={
              <div className="single-card">
                <div className="content-card">
                  <img src={cross} className="ext-btn" alt="" />
                  <img src={bridgeIcon} className="areas-image" alt="" />
                  <div className="focus-modal-contents">
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.interoperabilityChallenges")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t(
                        "homePage.ourThesis.interoperabilityChallengesContent"
                      )}
                    </p>
                    <h4 className="h4-card-back">
                      {t("homePage.ourThesis.stacknovaBridgeSolution")}
                    </h4>
                    <p className="paragraph-focus-card">
                      {t("homePage.ourThesis.stacknovaBridgeSolutionContent")}
                    </p>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default OurThesis;
