import { useDispatch } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur } from "../reducers/blurSlice";
import tokensByChainId from "../utils/tokensByChainId";
import { presaleAddressesByChainId } from "../utils/contractAddressesByChainId";
const { Contract, ethers } = require("ethers");

const ERC20_ABI = [
  "function approve(address spender, uint256 amount) public returns (bool)",
  "function allowance(address owner, address spender) public view returns (uint256)",
];

const useApprove = () => {
  const dispatch = useDispatch();
  const { address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const checkAndApproveToken = async (currency, amount) => {
    dispatch(setBlur(true)); // Start blur effect

    try {
      if (["ETH", "BNB", "MATIC", "AVAX"].includes(currency)) return true;

      const tokenAddresses = tokensByChainId[chainId] || {};
      const tokenAddress = tokenAddresses[currency]?.address;

      if (!tokenAddress) return;

      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(tokenAddress, ERC20_ABI, signer);

      const allowance = await tokenContract.allowance(
        address,
        presaleAddressesByChainId[chainId]
      );
      if (allowance.lt(amount)) {
        const tx = await tokenContract.approve(
          presaleAddressesByChainId[chainId],
          amount
        );
        await tx.wait();
      }
      return true;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setBlur(false)); // End blur effect
    }
  };

  return { checkAndApproveToken };
};

export default useApprove;
