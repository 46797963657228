import { useDispatch, useSelector } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur } from "../reducers/blurSlice";
import { setModal1, setModal2 } from "../reducers/modalSlice";
import { truncateToDecimalPlace } from "../utils/helpers";
import tokensByChainId from "../utils/tokensByChainId";
import { presaleAddressesByChainId } from "../utils/contractAddressesByChainId";
const { Contract, ethers } = require("ethers");

const PRESALE_ABI = [
  "event TokensPurchased(address indexed user, address indexed ref, uint256 amount, uint256 price, uint256 sold, uint256 round, uint256 investmentUSD, int256 currencyPrice, uint256 novaPoints)",
  "event NovaPointsAwarded(address indexed user, uint256 points)",
  "function purchaseTokens(address ref_, address tokenAddress_, uint256 amount_) external payable",
];

const Currency = {
  ETH: "ETH",
  BNB: "BNB",
  MATIC: "MATIC",
  AVAX: "AVAX",
  ARB: "ARB",
  USDT: "USDT",
  USDC: "USDC",
  DAI: "DAI",
};

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const getReferrerAddress = async (referrerUrl) => {
  if (!referrerUrl) {
    return ZERO_ADDRESS;
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ referrerUrl }),
        // credentials: "include", // Ensure cookies are sent with the request
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.referrerAddress;
    } else {
      return ZERO_ADDRESS;
    }
  } catch (error) {
    return ZERO_ADDRESS;
  }
};

const getActivePresaleStage = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getActivePresaleStage`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        // credentials: "include", // Ensure cookies are sent with the request
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const getParsedAmount = (currency, value, chainId) => {
  const decimals = tokensByChainId[chainId][currency].decimals;
  return ethers.utils.parseUnits(value, decimals);
};

const extractTransactionPayload = (
  tokensPurchasedEvent,
  receipt,
  referrerAddr,
  activePresaleStage,
  currency,
  address,
  chainId,
  referrerUrl,
  referralLink
) => {
  const decimals = tokensByChainId[chainId][currency].decimals;

  const purchaseAmount = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.amount,
    decimals
  );

  const transactionHash = receipt.transactionHash;
  const blockNumber = receipt.blockNumber;
  const gasUsed = receipt.gasUsed.toNumber();
  const gasPrice = receipt.effectiveGasPrice;
  const snovaTokens = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.sold,
    18
  );

  const transactionDate = new Date().toISOString();
  const investmentUSD = ethers.utils.formatUnits(
    tokensPurchasedEvent.args.investmentUSD,
    18
  );
  const currencyPrice = tokensPurchasedEvent.args.currencyPrice.toString();
  const novaPoints = tokensPurchasedEvent.args.novaPoints.toString();
  //!!!!!!SUPER IMPORTANT TO UNCOMMENT!!!!!!!//////
  // if (tokensPurchasedEvent.args.ref.toString() !== referrerAddr) {
  //   return {
  //     error: "Referral address mismatch detected. To be investigated",
  //   };
  // }

  return {
    address,
    purchaseAmount,
    currency_price: currencyPrice,
    purchase_amount_dollar: truncateToDecimalPlace(investmentUSD, 2),
    currency,
    network: chainId,
    transactionHash,
    contractAddress: presaleAddressesByChainId[chainId],
    snovaTokens,
    novaPoints,
    transactionDate,
    referrerUrl,
    referrerAddress: tokensPurchasedEvent.args.ref.toString(),
    presaleStage: activePresaleStage,
    gasUsed,
    gasPrice: gasPrice.toString(),
    blockNumber,
    referralLink,
  };
};

const usePurchase = () => {
  const dispatch = useDispatch();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const referrer = useSelector((state) => state.referrer.referrer);
  const referralLink = useSelector((state) => state.referral.referral);
  const referrerAddr = useSelector((state) => state.referrer.referrerAddress);

  const purchase = async (amount, currency) => {
    const referrerUrl = referrer;

    dispatch(setBlur(true));
    if (!isConnected) throw new Error("User disconnected");
    if (!referrerAddr) {
      referrerAddr = await getReferrerAddress(referrerUrl);
    }
    if (referrerAddr === null) {
      dispatch(
        setModal2({
          isOpen: true,
          data: { error: "Failed to get referrer address" },
        })
      );
      return;
    }

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const PresaleContract = new Contract(
      presaleAddressesByChainId[chainId],
      PRESALE_ABI,
      signer
    );
    try {
      const parsedAmount = getParsedAmount(currency, amount, chainId);
      const tokenAddresses = tokensByChainId[chainId] || {};
      const tokenAddress = tokenAddresses[currency]?.address;
      if (!tokenAddress) {
        dispatch(
          setModal2({
            isOpen: true,
            data: {
              error: `Token address for ${currency} not found on chain ${chainId}`,
            },
          })
        );
        return;
      }

      const transactionOptions = ["ETH", "BNB", "MATIC", "AVAX"].includes(
        currency
      )
        ? { value: parsedAmount }
        : { value: 0 };
      const passedAmountBasedOnCurrency = [
        "ETH",
        "BNB",
        "MATIC",
        "AVAX",
      ].includes(currency)
        ? 0
        : parsedAmount;

      const transactionResponse = await PresaleContract.purchaseTokens(
        referrerAddr,
        tokenAddress,
        passedAmountBasedOnCurrency,
        {
          ...transactionOptions,
          gasLimit: ethers.utils.hexlify(1000000),
        }
      );

      const receipt = await transactionResponse.wait();

      if (receipt.status === 1) {
        const tokensPurchasedEvent = receipt.events.find(
          (event) => event.event === "TokensPurchased"
        );

        if (!tokensPurchasedEvent) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: "TokensPurchased event not found in receipt." },
            })
          );
          return;
        }
        const activePresaleStage = await getActivePresaleStage();
        if (!activePresaleStage) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: "Failed to get active presale stage" },
            })
          );
          return;
        }

        const transactionPayload = extractTransactionPayload(
          tokensPurchasedEvent,
          receipt,
          referrerAddr,
          activePresaleStage,
          currency,
          address,
          chainId,
          referrerUrl,
          referralLink
        );

        if (transactionPayload.error) {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: transactionPayload.error },
            })
          );
          return;
        }

        // Make the API call, no need to manually add the JWT token, since it's already in the HttpOnly cookie
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/transactionHandler`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(transactionPayload),
            credentials: "include", // Ensure cookies are sent with the request
          }
        );

        if (response.ok) {
          dispatch(setModal1({ isOpen: true, data: transactionPayload }));
        } else {
          dispatch(
            setModal2({
              isOpen: true,
              data: { error: "Failed to save transaction" },
            })
          );
        }
      } else {
        dispatch(
          setModal2({ isOpen: true, data: { error: "Transaction failed." } })
        );
      }
    } catch (error) {
      dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
    } finally {
      dispatch(setBlur(false));
    }
  };

  return { purchase };
};

export default usePurchase;
