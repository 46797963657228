import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import "./components/i18";
import { Provider } from "react-redux";
import store from "./store";
import ReactGA from "react-ga4";

// ReactGA.initialize("G-S0LCXFME7P");

// ReactGA.send({ hitType: "pageview", page: window.location.pathname });

// export default function trackButtonClick(buttonName) {
//   ReactGA.event({
//     category: "Button Click",
//     action: "Clicked",
//     label: buttonName,
//   });
// }

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <React.Suspense fallback="loading...">
        <QueryClientProvider client={queryClient}>
          <App />{" "}
        </QueryClientProvider>
      </React.Suspense>
    </Provider>
  </React.StrictMode>
);
