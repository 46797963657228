import React, { useState, useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCurrency,
  setSelectedFiat,
} from "../../../../reducers/currencySlice";
import { useTranslation } from "react-i18next";
import {
  getCurrencyData,
  currencyIcons,
  specialCurrencyLabels,
  getDefaultCurrencies,
} from "../../../../utils/currenciesUtils";

const CurrencySelector = () => {
  const { chainId, isConnected } = useWeb3ModalAccount();
  const [lastConnectedChainId, setLastConnectedChainId] = useState(() =>
    localStorage.getItem("lastConnectedChainId")
  );

  const { t, i18n } = useTranslation();
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);
  const [currencies, setCurrencies] = useState(() => {
    const defaultSymbols = getDefaultCurrencies();
    return defaultSymbols.map((symbol) => ({
      symbol: symbol,
      image: currencyIcons[symbol],
      label: specialCurrencyLabels[symbol] || symbol,
    }));
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (isConnected) {
      localStorage.setItem("lastConnectedChainId", chainId);
      setLastConnectedChainId(chainId);
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    let feedCurrencies;
    if (buyWithCard) {
      feedCurrencies = getCurrencyData(chainId, isConnected, 137);
    } else {
      feedCurrencies = getCurrencyData(
        chainId,
        isConnected,
        lastConnectedChainId
      );
    }

    let combinedCurrencies = [];
    const includesMatic = chainId === 137 || "MATIC" in feedCurrencies;

    if (buyWithCard) {
      const maticCurrencies = ["USD", "GBP", "EUR"];
      combinedCurrencies = maticCurrencies.map((symbol) => ({
        symbol,
        image: currencyIcons[symbol],
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    } else if (includesMatic && !buyWithCard) {
      const maticCurrencies = ["MATIC", "USDT", "USDC", "DAI"];
      combinedCurrencies = maticCurrencies.map((symbol) => ({
        symbol,
        image: currencyIcons[symbol],
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    } else {
      combinedCurrencies = Object.keys(feedCurrencies).map((symbol) => ({
        symbol,
        image: currencyIcons[symbol] || currencyIcons["ETH"],
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    }

    if (combinedCurrencies.length > 0) {
      setCurrencies(combinedCurrencies);
    }
  }, [chainId, isConnected, lastConnectedChainId, buyWithCard]);

  const handleCurrencyClick = (currency) => {
    if (
      currency.symbol === "USD" ||
      currency.symbol === "EUR" ||
      currency.symbol === "GBP"
    ) {
      dispatch(setSelectedFiat(currency.symbol));
    } else {
      dispatch(setSelectedCurrency(currency.symbol));
    }
  };

  return (
    <div
      className="unconnected css-1f35gka"
      style={{ justifyContent: "start" }}
    >
      <div className="css-1p1m4ay" style={{ flexWrap: "wrap" }}>
        <p className="text-[14px] text-[#79716B] mr-[8px]">
          {" "}
          {t("homePage.banner.use")}
        </p>
        {currencies.map((currency, index) => (
          <React.Fragment key={currency.symbol}>
            {index !== 0 && (
              <p className="text-[14px] ml-[8px] mr-[8px] text-[#79716B]">
                {t("homePage.banner.or")}
              </p>
            )}
            <div
              className="css-70qvj9"
              onClick={() => handleCurrencyClick(currency)}
            >
              <div className="css-759u60">
                {typeof currency.image === "string" ? (
                  <img
                    src={currency.image}
                    alt={currency.symbol}
                    style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  React.createElement(currency.image, {
                    style: {
                      width: "20px",
                      height: "20px",
                    },
                    "aria-label": currency.symbol,
                  })
                )}
                <p className="text-[14px] text-[#F5F5F4] ml-[4px]">
                  {currency.label}
                </p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CurrencySelector;
