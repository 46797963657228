import { useState, useEffect } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers5/react";
import { ReactComponent as MetaMaskLogo } from "../assets/img/metamask-icon.svg";
import { ReactComponent as CoinbaseWalletLogo } from "../assets/img/coinbase-icon.svg";
import { ReactComponent as TrustWalletLogo } from "../assets/img/trustwallet-icon.svg";
import { ReactComponent as WalletConnectLogo } from "../assets/img/walletconnect-icon.svg";
import { ReactComponent as PhantomLogo } from "../assets/img/phantom-icon.svg";

const walletLogos = {
  isMetaMask: MetaMaskLogo,
  isCoinbaseWallet: CoinbaseWalletLogo,
  isTrustWallet: TrustWalletLogo,
  isPhantom: PhantomLogo,
  isWalletConnect: WalletConnectLogo,
};

export const useWalletConnection = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const [wallets, setWallets] = useState({
    isMetaMask: false,
    isCoinbaseWallet: false,
    isTrustWallet: false,
    isPhantom: false,
    isWalletConnect: false,
  });

  useEffect(() => {
    const checkWalletConnection = () => {
      setWallets({
        isMetaMask: walletProvider?.isMetaMask || false,
        isCoinbaseWallet: walletProvider?.isCoinbaseWallet || false,
        isTrustWallet: walletProvider?.isTrust || false,
        isPhantom: walletProvider?.isPhantom || false,
        isWalletConnect: walletProvider?.isWalletConnect || false,
      });
    };

    checkWalletConnection();
  }, [walletProvider]);

  const getWalletLogo = (walletName) => {
    const WalletLogo = walletLogos[walletName];
    return WalletLogo ? <WalletLogo /> : null;
  };

  return { wallets, getWalletLogo };
};
