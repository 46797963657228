export const ExclamationIcon = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    width={20}
  >
    <style>{`.cls-1 { fill: #fff; stroke-width: 0; }`}</style>
    <path
      className="cls-1"
      d="M18,0C8.06,0,0,8.06,0,18s8.06,18,18,18,18-8.06,18-18S27.94,0,18,0ZM19.75,26.4h-3.49v-3.62c.07,0,3.49,0,3.49,0v3.62ZM19.75,20.87h-3.49v-11.42c.07.02,3.49,0,3.49,0v11.42Z"
    />
  </svg>
);
