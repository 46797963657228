const registryAddressesByChainId = {
  1: "0xYourContractAddressOnEthereum",
  10: "0xc104181A80581e8d7bC6BdF2E13AF049E734a28d", // Example on Optimism
  56: "0x39B4D7A8C0A2cddD2e96eCdc68fcf82FDF4094a0", // Example on BSC
  137: "0x846360DDb44D2C44b12Ec361e1B72816978C5Edc", // Example for Polygon
  324: "0xCFbe91a49B624758CCeCC6A2750b00E60C35589C", // Example on ZKsync
  8453: "0x9D30af26940ababf9A90941a230553D336633232", // Example on Base
  42161: "0x634B01A31ee2636f36088250567960e407bBC031", // Example on Arbitrum
  43114: "0xB21f4597cB439472aA9dC0eFFF66CCB9578Ba358", // Example on Avalanche
};

const presaleAddressesByChainId = {
  1: "0xYourContractAddressOnEthereum",
  10: "0x91237858e7E930567C133A1a6562F16512dc5Af5", // Example on Optimism
  56: "0x7d6CDEED26d9c688339614738D24544aDB8317CA", // Example on BSC
  137: "0x226eC973859e30869Aa32800Edc3aE0E6c2e2a65", // Example for Polygon
  324: "0x059E3E94004DDf2685bC261DCb89401Ae1Bec76a", // Example for ZKsync
  8453: "0x6Cd1eE871D06Bb650FBc89f8a72C28bc8d68A7E3", //Example on Base
  42161: "0xD41aD625E17D944D337e082506533f517Da5Ddd1", // Example on Arbitrum
  43114: "0xc6D179D2e567Db7BdceF0be3b46f332D6cB0D6Fc", //Example on Avalanche
};

export { registryAddressesByChainId, presaleAddressesByChainId };
