import React, { useState, useEffect } from "react";
import axios from "axios";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useComputeButtonContent } from "../../../../hooks/useComputeButtonContent";
import {
  updateBalance,
  updateBalanceInDollar,
  copyBalanceToInput,
} from "../../../../reducers/balanceSlice";
import { setBlur } from "../../../../reducers/blurSlice";
import { CoinsIcon } from "../Icons/CoinsIcon";
import useApprove from "../../../../hooks/useApprove";
import usePurchase from "../../../../hooks/usePurchase";
import useCheckApproval from "../../../../hooks/useCheckApproval";
import tokensByChainId from "../../../../utils/tokensByChainId";
import trackButtonClick from "../../../../index";
const { ethers } = require("ethers");

const Tooltip = React.memo(({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <div style={{ position: "relative" }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={showTooltip ? "true" : "false"}
        aria-label={text}
      >
        {children}
      </div>
      {showTooltip && (
        <div className="main-tp">
          <div />
          {text}
        </div>
      )}
    </div>
  );
});

export default function ConnectButton({
  children,
  className,
  buttonText,
  balance,
  balanceInDollar,
  ...rest
}) {
  const { open } = useWeb3Modal();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const selectedCurrency = useSelector(
    (state) => state.currency.selectedCurrency
  );
  const dollarAmount = useSelector((state) => state.dollar.dollarAmount);
  const ethAmount = useSelector((state) => state.amounts.ethAmount);
  const balanceDollar = useSelector((state) => state.balance.balanceDollar);
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { checkAndApproveToken } = useApprove();
  const { purchase } = usePurchase();
  const checkApprovalStatus = useCheckApproval(selectedCurrency, ethAmount);

  balanceInDollar = balanceInDollar ?? balanceDollar;

  const buttonContent = useComputeButtonContent({
    isConnected,
    address,
    balance,
    balanceInDollar,
    selectedCurrency,
    buttonText,
    dollarAmount,
    className,
  });

  useEffect(() => {
    if (isConnected && address) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/userHandler`, {
          walletAddress: address,
        })
        .catch((error) => {
          console.error("Error connecting to userHandler backend:", error);
        });
    }
  }, [isConnected, address]);

  const handleClick = async (isAddressVersion, isApproved) => {
    if (isAddressVersion) {
      try {
        // trackButtonClick("Connecting to the wallet using header button");
        await open();
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else {
      if (!isConnected) {
        try {
          // trackButtonClick(
          //   "Connecting to the wallet using multi-functional button"
          // );
          await open();
        } catch (error) {
          console.error("Error connecting to wallet:", error);
        }
      } else {
        if (ethAmount && ethAmount !== "") {
          try {
            const decimals =
              tokensByChainId[chainId][selectedCurrency].decimals;
            const parsedAmount = ethers.utils.parseUnits(
              ethAmount.toString(),
              decimals
            );
            dispatch(setBlur(true));
            if (!isApproved) {
              // trackButtonClick("Approve Button Clicked");
              await checkAndApproveToken(selectedCurrency, parsedAmount);
              await checkApprovalStatus(); // Check approval status again after approving
            } else {
              // trackButtonClick("Purchase Button Clicked");
              await purchase(ethAmount, selectedCurrency);
              await checkApprovalStatus(); // Check approval status again after purchase
            }
            dispatch(setBlur(false));
          } catch (error) {
            console.error("Error during approve and purchase:", error);
            dispatch(setBlur(false));
          }
        }
      }
    }
  };

  function truncateToDecimalPlace(num, decimalPlaces) {
    const numStr = num?.toString();
    const regex = new RegExp(`^(.*\\..{${decimalPlaces}})|.*$`);
    const matches = numStr?.match(regex);
    return matches && matches[1] ? matches[1] : numStr;
  }

  return (
    <div>
      {buyWithCard &&
      className.includes(
        "ant-btn button-bna flex itmes-center conten jad css-dev-only-do-not-override-1q8nizr ant-btn-primary"
      ) ? (
        <Tooltip text={t("toolTips.cardPaymentTip")}>
          <button
            id="buyWithCardButton"
            className={`${className} disabled-button`}
            {...rest}
          >
            <span className="image-icon">{<CoinsIcon />}</span>
            {t("homePage.banner.buyToken")}
          </button>
        </Tooltip>
      ) : buttonContent.text === t("homePage.banner.insuficientBalance") &&
        buttonContent.isDisabled ? (
        <Tooltip text={t("Header.balanceTopUp")}>
          <button
            id="insufficientBalanceButton"
            onClick={() => {}}
            className={`${className} disabled-button`}
            {...rest}
          >
            <span className="image-icon">{buttonContent.image()}</span>
            {buttonContent.text}
          </button>
        </Tooltip>
      ) : buttonContent.text === t("homePage.banner.buyToken") ? (
        <Tooltip text={t("Header.presaleBannerPaused")}>
          <button
            id="buyTokenButton"
            onClick={
              buttonContent.isDisabled
                ? () => {}
                : () => handleClick(false, buttonContent.isApproved)
            }
            className={`${className} ${
              buttonContent.isDisabled ? "disabled-button" : ""
            }`}
            {...rest}
          >
            <span className="image-icon">{buttonContent.image()}</span>
            {buttonContent.text}
          </button>
        </Tooltip>
      ) : buttonContent.text === t("homePage.banner.minimumInvestment") &&
        buttonContent.isDisabled ? (
        <button
          id="minimumInvestmentButton"
          onClick={
            buttonContent.isDisabled
              ? () => {}
              : () => handleClick(false, buttonContent.isApproved)
          }
          className={`${className} ${
            buttonContent.isDisabled ? "disabled-button" : ""
          }`}
          {...rest}
        >
          <span className="image-icon">{buttonContent.image()}</span>
          {buttonContent.text}
        </button>
      ) : buttonContent.text ===
        `${Number(truncateToDecimalPlace(balance, 4)).toFixed(
          4
        )} ${selectedCurrency} ($${Number(
          truncateToDecimalPlace(balanceInDollar, 2)
        ).toFixed(2)})` ? (
        <button
          id="balanceButton"
          onClick={() => dispatch(copyBalanceToInput())}
          className={className}
          {...rest}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === "img") {
              return React.cloneElement(child, { src: buttonContent.image });
            }
            return child;
          })}
          {buttonContent.text}
        </button>
      ) : (
        <button
          id="defaultButton"
          onClick={
            buttonContent.isDisabled
              ? () => {}
              : () =>
                  handleClick(
                    buttonContent.isAddressVersion,
                    buttonContent.isApproved
                  )
          }
          className={`${className} ${
            buttonContent.isDisabled ? "disabled-button" : ""
          }`}
          {...rest}
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === "img") {
              return React.cloneElement(child, { src: buttonContent.image });
            }
            return child;
          })}
          {buttonContent.text}
        </button>
      )}
    </div>
  );
}
