// src/reducers/modalSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modal1: { isOpen: false, data: null },
  modal2: { isOpen: false, data: null },
  modal3: { isOpen: false, data: null },
  modal4: { isOpen: false, data: null }, // Added modal4
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setModal1(state, action) {
      state.modal1 = action.payload;
    },
    setModal2(state, action) {
      state.modal2 = action.payload;
    },
    setModal3(state, action) {
      state.modal3 = action.payload;
    },
    setModal4(state, action) {
      // Added setModal4 action
      state.modal4 = action.payload;
    },
  },
});

export const { setModal1, setModal2, setModal3, setModal4 } =
  modalSlice.actions;

export default modalSlice.reducer;
