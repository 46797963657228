// import { useState, useEffect, useCallback } from "react";
// import tokensByChainId from "../utils/tokensByChainId";
// const ethers = require("ethers");

// const useBalanceData = ({
//   isConnected,
//   walletProvider,
//   chainId,
//   selectedCurrency,
//   address,
//   fetchPriceForCurrency,
// }) => {
//   const [balance, setBalance] = useState(null);
//   const [balanceInDollar, setBalanceInDollar] = useState(null);

//   const fetchBalanceAndPrice = useCallback(async () => {
//     const startTime = performance.now();

//     if (!isConnected) {
//       setBalance(null);
//       setBalanceInDollar(null);
//       const endTime = performance.now();
//       return;
//     }

//     try {
//       const ethersProvider = new ethers.providers.Web3Provider(walletProvider);

//       const signer = await ethersProvider.getSigner();

//       const userAddress = await signer.getAddress();

//       // if (userAddress !== address) {
//       //   console.log("Address mismatch or not updated yet");
//       //   return;
//       // }

//       const balanceStartTime = performance.now();
//       let newBalance;
//       let formattedBalance;
//       const stableCoinAddress = {
//         1: {
//           USDT: {
//             name: "ETH",
//             address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
//           },
//           USDC: {
//             name: "ETH",
//             address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
//           },
//         },
//         42161: {
//           USDT: {
//             name: "ARB",
//             address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//           },
//           USDC: {
//             name: "ARB",
//             address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
//           },
//         },
//         10: {
//           USDT: {
//             name: "OP",
//             address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
//           },
//           USDC: {
//             name: "OP",
//             address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
//           },
//         },
//         8453: {
//           USDC: {
//             name: "BASE",
//             address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
//           },
//           DAI: {
//             name: "BASE",
//             address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
//           },
//         },
//         56: {
//           USDT: {
//             name: "BNB",
//             address: "0x55d398326f99059fF775485246999027B3197955",
//           },
//           USDC: {
//             name: "BNB",
//             address: "0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2",
//           },
//         },
//         137: {
//           USDT: {
//             name: "MATIC",
//             address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
//           },
//           USDC: {
//             name: "MATIC",
//             address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
//           },
//         },
//         43114: {
//           USDT: {
//             name: "AVAX",
//             address: "0x625E7708f30cA75bfd92586e17077590C60eb4cD",
//           },
//           USDC: {
//             name: "AVAX",
//             address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
//           },
//         },
//         324: {
//           USDT: {
//             name: "ZKSYNC",
//             address: "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
//           },
//           USDC: {
//             name: "ZKSYNC",
//             address: "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
//           },
//         },
//       };

//       if (["ETH", "BNB", "MATIC", "AVAX"].includes(selectedCurrency)) {
//         newBalance = await ethersProvider.getBalance(userAddress);
//         let rawFormatted = ethers.utils.formatEther(newBalance);
//         formattedBalance = truncateNumber(rawFormatted, 4);
//       } else {
//         const tokenInfo = stableCoinAddress[chainId]?.[selectedCurrency];
//         if (tokenInfo && tokenInfo.address) {
//           const contract = new ethers.Contract(
//             tokenInfo.address,
//             ["function balanceOf(address owner) view returns (uint256)"],
//             signer
//           );
//           newBalance = await contract.balanceOf(userAddress);
//           formattedBalance =
//             chainId === 56
//               ? truncateNumber(newBalance / 10 ** 18, 2)
//               : truncateNumber(newBalance / 10 ** 6, 2);
//         } else {
//           console.error("Unsupported chain ID or currency");
//           return;
//         }
//       }

//       setBalance(formattedBalance);

//       const priceStartTime = performance.now();
//       const currencyPrice = await fetchPriceForCurrency(selectedCurrency);
//       const priceEndTime = performance.now();

//       if (formattedBalance !== null && currencyPrice !== null) {
//         const newBalanceInDollar = currencyPrice * formattedBalance;
//         if (newBalanceInDollar !== balanceInDollar) {
//           setBalanceInDollar(newBalanceInDollar);
//         }
//       }

//       const endTime = performance.now();
//     } catch (error) {
//       console.error("Error fetching balance and price:", error);
//     }
//   }, [
//     isConnected,
//     walletProvider,
//     chainId,
//     selectedCurrency,
//     address,
//     fetchPriceForCurrency,
//     balanceInDollar,
//   ]);

//   useEffect(() => {
//     fetchBalanceAndPrice();
//   }, [fetchBalanceAndPrice]);

//   function truncateNumber(number, decimalPlaces) {
//     const factor = Math.pow(10, decimalPlaces);
//     return Math.floor(number * factor) / factor;
//   }

//   return { balance, balanceInDollar, fetchBalanceAndPrice };
// };

// export default useBalanceData;

import { useState, useEffect, useCallback } from "react";
import tokensByChainId from "../utils/tokensByChainId";
const ethers = require("ethers");

const useBalanceData = ({
  isConnected,
  walletProvider,
  chainId,
  selectedCurrency,
  address,
  fetchPriceForCurrency,
}) => {
  // console.log("USE BALANCE INVOCATION");
  const [balance, setBalance] = useState(null);
  const [balanceInDollar, setBalanceInDollar] = useState(null);

  const fetchBalanceAndPrice = useCallback(async () => {
    const startTime = performance.now();

    if (!isConnected) {
      setBalance(null);
      setBalanceInDollar(null);
      const endTime = performance.now();
      return;
    }

    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();

      let newBalance;
      let formattedBalance;

      if (["ETH", "BNB", "MATIC", "AVAX"].includes(selectedCurrency)) {
        newBalance = await ethersProvider.getBalance(userAddress);
        let rawFormatted = ethers.utils.formatEther(newBalance);
        formattedBalance = truncateNumber(rawFormatted, 4);
      } else {
        const tokenInfo = tokensByChainId[chainId]?.[selectedCurrency];
        if (tokenInfo && tokenInfo.address) {
          const contract = new ethers.Contract(
            tokenInfo.address,
            ["function balanceOf(address owner) view returns (uint256)"],
            signer
          );
          newBalance = await contract.balanceOf(userAddress);
          formattedBalance = truncateNumber(
            newBalance / 10 ** tokenInfo.decimals,
            2
          );
        } else {
          return;
        }
      }

      setBalance(formattedBalance);

      const currencyPrice = await fetchPriceForCurrency(
        selectedCurrency,
        chainId
      );
      // console.log("PRICE", currencyPrice);

      if (formattedBalance !== null && currencyPrice !== null) {
        const newBalanceInDollar = currencyPrice * formattedBalance;
        setBalanceInDollar(newBalanceInDollar);
        // console.log("BALANCE IN DOLLAR", newBalanceInDollar);
      }
    } catch (error) {
      console.error("Error fetching balance and price:", error);
    }
  }, [
    isConnected,
    walletProvider,
    chainId,
    selectedCurrency,
    address,
    fetchPriceForCurrency,
  ]);

  useEffect(() => {
    fetchBalanceAndPrice();
  }, [fetchBalanceAndPrice]);

  function truncateNumber(number, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
  }

  return { balance, balanceInDollar, fetchBalanceAndPrice };
};

export default useBalanceData;
