const tokensByChainId = {
  1: {
    ETH: {
      decimals: 18,
      name: "ETH",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    USDT: {
      decimals: 6,
      name: "ETH",
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    },
    USDC: {
      decimals: 6,
      name: "ETH",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    DAI: {
      decimals: 18,
      name: "ETH",
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    },
  },
  137: {
    MATIC: {
      decimals: 18,
      name: "MATIC",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    USDT: {
      decimals: 6,
      name: "MATIC",
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    },
    USDC: {
      decimals: 6,
      name: "MATIC",
      address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    },
    DAI: {
      decimals: 18,
      name: "MATIC",
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    },
  },
  42161: {
    ARB: {
      // ARB not native
      decimals: 18,
      name: "ARB",
      address: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    },
    USDT: {
      decimals: 6,
      name: "ARB",
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    },
    USDC: {
      decimals: 6,
      name: "ARB",
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    },
    DAI: {
      decimals: 18,
      name: "ARB",
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
  },
  10: {
    OP: {
      decimals: 18,
      name: "OP",
      address: "0x4200000000000000000000000000000000000042",
    },
    USDT: {
      decimals: 6,
      name: "OP",
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
    },

    USDC: {
      decimals: 6,
      name: "OP",
      address: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    },
    DAI: {
      decimals: 18,
      name: "OP",
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    },
  },
  8453: {
    ETH: {
      decimals: 18,
      name: "BASE",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    USDT: {
      decimals: 6,
      name: "BASE",
      address: "0x7f5373AE26c3E8FfC4c77b7255DF7eC1A9aF52a6", // ?
    },
    USDC: {
      decimals: 6,
      name: "BASE",
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    },
    DAI: {
      decimals: 18,
      name: "BASE",
      address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    },
  },
  56: {
    BNB: {
      decimals: 18,
      name: "BSC",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    USDT: {
      decimals: 18,
      name: "BSC",
      address: "0x55d398326f99059fF775485246999027B3197955",
    },
    USDC: {
      decimals: 18,
      name: "BSC",
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    },
    DAI: {
      decimals: 18,
      name: "BSC",
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    },
  },
  43114: {
    AVAX: {
      decimals: 18,
      name: "AVAX",
      address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    USDT: {
      decimals: 6,
      name: "AVAX",
      address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    },
    USDC: {
      decimals: 6,
      name: "AVAX",
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
    },
    DAI: {
      decimals: 18,
      name: "AVAX",
      address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
    },
  },
  324: {
    // ETH: {
    //  decimals: 18,
    //   name: "ZKSYNC",
    //   address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    // },
    ZK: {
      decimals: 18,
      name: "ZKSYNC",
      address: "0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E",
    },
    USDT: {
      decimals: 6,
      name: "ZKSYNC",
      address: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    },
    USDC: {
      decimals: 6,
      name: "ZKSYNC",
      address: "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
    },
    DAI: {
      decimals: 18,
      name: "DAI",
      address: "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
    },
  },
  // Add other chain IDs and their token addresses similarly
};

export default tokensByChainId;
