import React from "react";
import "./Eclipse.css";
import Circle from "../../../assets/video/circles-rotation.webm";
import communityIcon from "../../../assets/img/community-power.png";
import securityIcon from "../../../assets/img/btc-security.png";
import innovationIcon from "../../../assets/img/ai-innovation.png";
import { useTranslation } from "react-i18next";
const Eclipse = () => {
  const { t } = useTranslation();
  return (
    <div className="eclipse-main">
      {/* Desktop Version */}
      <div className="desk-only section-3_container__Rbmzm ">
        <div className="panel-1">
          <div className="ellipses">
            <video autoPlay muted loop>
              <source type="video/webm" src={Circle} />
            </video>
          </div>
          <div>
            <div className="section-3_acceleratorIcon___t779">
              <img src={innovationIcon} className="ai-icon" />
              <span className="section-3_acceleratorText__6QKzJ">
                {t("homePage.eclipse.acceleratorText")}
              </span>
              <div className="section-3_popup__q_f81">
                <h6 className="section-3_h6__eii5y">
                  {t("homePage.eclipse.aiInnovation")}
                </h6>
                <div className="section-3_body2__NoQ5Q">
                  {t("homePage.eclipse.aiInnovationDescription")}
                </div>
              </div>
            </div>
            <div className="section-3_launchpadIcon__RsDk7">
              <img src={securityIcon} className="security-icon" />
              <span className="section-3_launchpadText__GU3Zb">
                {t("homePage.eclipse.bitcoin")}
              </span>
              <div className="section-3_popup__q_f81">
                <h6 className="section-3_h6__eii5y">
                  {t("homePage.eclipse.bitcoinSecurity")}
                </h6>
                <div className="section-3_body2__NoQ5Q">
                  {t("homePage.eclipse.bitcoinSecurityDescription")}
                </div>
              </div>
            </div>
            <div className="section-3_growthAgencyIcon__baZ47">
              <img src={communityIcon} className="community-icon" />
              <span className="section-3_growthAgencyText__7_0T0">
                {t("homePage.eclipse.community")}
              </span>
              <div className="section-3_popup__q_f81">
                <h6 className="section-3_h6__eii5y">
                  {t("homePage.eclipse.communityPower")}
                </h6>
                <div className="section-3_body2__NoQ5Q">
                  {t("homePage.eclipse.communityPowerDescription")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile version */}
      <div className="mbl-only mt-5as">
        <div className="section-3-mobile_container__vXiWF">
          <div className="panel-1" />
          <div className=" mbl-hide flex flex-col items-center absolute w-full text-center z-10">
            <h1>{t("homePage.eclipse.mobileTitle")}</h1>
            <h1>
              <span className="text-[#E2CD6C]">
                {t("homePage.eclipse.mobileSubtitle")}
              </span>
            </h1>
          </div>
          <div className="flex flex-col items-center panel-2 mtless mt-96 relative">
            <div className="section-3-mobile_popup__YpZUj">
              <div className="w-full flex flex-col items-center eclipsetar">
                <img src={innovationIcon} className="ai-icon" />
                <div className="section-3-mobile_launchpadIcon__5MIFC" />
              </div>
              <h6 className="section-3-mobile_h6__tvakd">
                {t("homePage.eclipse.aiInnovation")}
              </h6>
              <div className="section-3-mobile_body2__BWU13">
                {t("homePage.eclipse.aiInnovationDescription")}
              </div>
            </div>
            <div className="section-3-mobile_popup__YpZUj">
              <div className="w-full flex flex-col items-center eclipsetar">
                <img src={securityIcon} className="security-icon" />
                <div className="section-3-mobile_growthAgencyIcon__NLI0d" />
              </div>
              <h6 className="section-3-mobile_h6__tvakd">
                {t("homePage.eclipse.bitcoinSecurity")}
              </h6>
              <div className="section-3-mobile_body2__BWU13">
                {t("homePage.eclipse.bitcoinSecurityDescription")}
              </div>
            </div>
            <div className="section-3-mobile_popup__YpZUj">
              <div className="w-full flex flex-col items-center eclipsetar">
                <img src={communityIcon} className="community-icon" />
                <div className="section-3-mobile_acceleratorIcon__DHFAg" />
              </div>
              <h6 className="section-3-mobile_h6__tvakd">
                {t("homePage.eclipse.communityPower")}
              </h6>
              <div className="section-3-mobile_body2__BWU13">
                {t("homePage.eclipse.communityPowerDescription")}
              </div>
            </div>
            {/*
            <div className="section-3-mobile_line__Em3Ui" />
            <div className="section-3-mobile_acceleratorIcon2__NvFUR" />
            */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eclipse;
