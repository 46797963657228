import { useDispatch, useSelector } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur2 } from "../reducers/blurSlice";
import { setModal2, setModal3 } from "../reducers/modalSlice";
import tokensByChainId from "../utils/tokensByChainId";
import { registryAddressesByChainId } from "../utils/contractAddressesByChainId";
const { Contract, ethers } = require("ethers");

const ABI = [
  "function claimRef(address[] calldata tokens_) external",
  "event ReferralRewardsClaimed(address indexed ref, address indexed token, uint256 amount)",
];

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const useClaimReferralRewards = () => {
  const dispatch = useDispatch();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const referrer = useSelector((state) => state.referrer.referrer);

  const claimRewards = async (rewardsChainId, claimedData) => {
    const tokensConfig = tokensByChainId[rewardsChainId];
    const tokens = Object.values(tokensConfig).map((token) => token.address);

    dispatch(setBlur2(true));
    if (!isConnected) {
      console.error("User disconnected");
      throw new Error("User disconnected");
    }
    // console.log(rewardsChainId, chainId, "COMPARING");
    if (chainId !== rewardsChainId) {
      try {
        const formattedChainId = ethers.utils.hexStripZeros(
          ethers.utils.hexlify(rewardsChainId)
        );
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: formattedChainId }],
        });
      } catch (switchError) {
        console.error("Failed to switch network:", switchError);
        dispatch(
          setModal2({
            isOpen: true,
            data: { error: "Please switch to the correct network." },
          })
        );
        dispatch(setBlur2(false));
        return;
      }
    }

    // console.log("Creating ethers provider and signer...");
    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    // console.log("Signer obtained:", signer);

    const contract = new Contract(
      registryAddressesByChainId[rewardsChainId],
      ABI,
      signer
    );
    // console.log("Contract instantiated:", contract);

    try {
      //   console.log("Sending claimRef transaction with tokens:", tokens);
      const transactionResponse = await contract.claimRef(tokens);
      //   console.log("Transaction response received:", transactionResponse);

      const receipt = await transactionResponse.wait();
      //   console.log("Transaction receipt received:", receipt);

      if (receipt.status === 1) {
        const claimedEvents = receipt.events
          .filter((event) => event.event === "ReferralRewardsClaimed")
          .map((event) => ({
            ref: event.args.ref,
            token: event.args.token,
            amount: ethers.utils.formatUnits(event.args.amount, 18),
          }));
        // console.log("Claimed events:", claimedEvents);

        // Trigger Netlify function to update referral link rewards
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/updateReferralLinkRewards`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ address, network: rewardsChainId }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update referral link rewards");
        }

        dispatch(
          setModal3({
            isOpen: true,
            data: {
              network: rewardsChainId,
              totalNetworkReward: claimedData.totalNetworkReward,
              amounts: claimedData.amounts,
            },
          })
        );
      } else {
        console.error("Transaction failed with status:", receipt.status);
        dispatch(
          setModal2({ isOpen: true, data: { error: "Transaction failed." } })
        );
      }
    } catch (error) {
      console.error("Error during claimRef transaction:", error);
      dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
    } finally {
      dispatch(setBlur2(false));
    }
  };

  return { claimRewards };
};

export default useClaimReferralRewards;
