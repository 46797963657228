import { useState } from "react";
import useReferrer from "./useReferrer";

const useStoreReferralLink = () => {
  const referrer = useReferrer();
  const [error, setError] = useState(null);

  const storeReferralLink = async (walletAddress, referralId) => {
    if (!walletAddress || !referralId) {
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/referralLinkHandler`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            walletAddress,
            referrerId: referrer,
            referralId,
            totalRewards: [],
            totalRewardsClaimed: [],
            pendingRewards: [],
            boughtSnova: 0,
            pendingRewardsSnova: 0,
            totalSnova: 0,
            boughtNovaPoints: 0,
            novaRewards: 0,
            totalNovaPoints: 0,
            activeReferrals: [],
            totalRewardsInDollar: 0,
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to store referral link");
      }
      return await response.json().then((data) => data.referral_id);
    } catch (error) {
      setError(error);
      throw error;
    }
  };

  return { storeReferralLink, error };
};

export default useStoreReferralLink;
